import { Hint } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { Button } from '@sparx/sparx-design/components';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { useAnimate } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

import { useSparxQuestionContext } from '../../../question/SparxQuestionContext';
import { FractionHintInfo, HintInfo } from '../types';
import styles from './FractionsHints.module.css';

export const HintArrow = ({ hint }: { hint: FractionHintInfo | undefined }) => {
  const [shouldShow, setShouldShow] = useState(!hint?.partHasInput);
  // once we have input in the part, we should hide the arrow
  useEffect(() => {
    if (hint?.partHasInput) {
      setShouldShow(false);
    }
  }, [hint?.partHasInput]);
  return (
    <>
      {hint && (
        <div
          className={classNames({ [styles.HintArrow]: true, [styles.Hidden]: !shouldShow })}
          role="presentation"
        >
          👉
        </div>
      )}
    </>
  );
};

export const ResetButton = ({ hint }: { hint: FractionHintInfo | undefined }) => {
  const { setOpenElementRef } = useSparxQuestionContext();
  return (
    <>
      {hint && (
        <div
          className={classNames({
            [styles.ResetButtonContainer]: true,
            [styles.Hidden]: !hint.partHasInput,
          })}
          data-reset-button
          role="presentation"
        >
          <Button
            onClick={() => {
              hint.onReset();
              setOpenElementRef('');
            }}
            variant="text"
            className={styles.ResetButton}
          >
            reset
          </Button>
        </div>
      )}
    </>
  );
};

const SimplifyFractionHint = ({
  hintInfo,
  numHintsGiven,
}: {
  hintInfo: HintInfo | undefined;
  numHintsGiven: number;
}) => {
  const videoId = getVideoForHint(hintInfo?.hintType);
  const { openHintVideo } = useSparxQuestionContext();
  const [animationScope, animate] = useAnimate();

  useEffect(() => {
    const doAnimate = async () => {
      if (numHintsGiven === 1) {
        await animate(animationScope.current, { opacity: [0, 1] }, { duration: 0.4 });
      }
      await animate(
        animationScope.current,
        {
          rotate: [0, 1, -1, 1, 0],
        },
        { duration: 0.3, delay: 0.3 },
      );
    };
    doAnimate();
  }, [numHintsGiven, animate, animationScope]);

  return (
    <div ref={animationScope} className={styles.HintContainer}>
      <div className={styles.Hint}>
        {numHintsGiven > 1 ? (
          <>
            <b>Keep going! You can still simplify this answer. </b>
          </>
        ) : (
          <>
            <b>Nearly! Fully simplify your answer</b> to make this question correct.{' '}
          </>
        )}
        {openHintVideo && videoId && (
          <button
            className={classNames(styles.VideoLink, accessibilityStyles.FocusTarget)}
            onClick={() => openHintVideo({ videoId, hintType: Hint.SIMPLIFY_FRACTION })}
          >
            See how to simplify fractions
          </button>
        )}
      </div>
    </div>
  );
};

export const HintWrapper = ({
  hintInfo,
  numHintsGiven,
  children,
}: {
  hintInfo: HintInfo | undefined;
  numHintsGiven: number;
  children: ReactNode;
}) => {
  const hintElement =
    hintInfo?.hintType === Hint.SIMPLIFY_FRACTION ? (
      <SimplifyFractionHint hintInfo={hintInfo} numHintsGiven={numHintsGiven} />
    ) : null;

  return (
    <>
      {hintElement}
      {children}
    </>
  );
};

const getVideoForHint = (hintType: Hint | undefined) => {
  switch (hintType) {
    case Hint.SIMPLIFY_FRACTION:
      return 'ec27a003-1489-4000-9214-e5878eaa1049';
    default:
      return '';
  }
};
