// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "sparxweb/swmsg/sparxweb.proto" (package "sparxweb", syntax proto3),// tslint:disable
// @ts-nocheck
import { SubmitStudentSentimentFeedbackResponse } from '../../apis/sparx/interaction/feedback/v1/feedback';
import { SubmitStudentSentimentFeedbackRequest } from '../../apis/sparx/interaction/feedback/v1/feedback';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Level } from '../../genproto/apis/recall/recall';
import { TargetSet } from '../../genproto/apis/recall/recall';
import { FamilyState } from '../../genproto/apis/recall/recall';
import { ActivityFeedDay } from '../../apis/sparx/dataanalysis/aggserver/v1/aggserver';
import { Avatar } from '../../apis/sparx/games/leagueman/v1/leagueman';
import { Medal } from '../../apis/sparx/games/leagueman/v1/leagueman';
import { Result } from '../../apis/sparx/games/leagueman/v1/leagueman';
import { WeekIndex } from '../../apis/sparx/games/leagueman/v1/leagueman';
import { Store } from '../../apis/sparx/games/store/v1/store';
import { Update } from '../../apis/sparx/games/state/v1/state';
import { AnswerComponent } from '../answer/answer';
import { Action } from '../../apis/sparx/games/state/v1/state';
import { Completion } from './progress';
import { Init } from '../../apis/sparx/games/state/v1/state';
import { TaskState } from '../../apis/sparx/sparxweb/v1/taskstate';
import { VideoStatus } from '../../apis/sparx/sparxweb/v1/videostatus';
import { LessonSettings } from '../../genproto/apis/lessondeliverysettings/lessondeliverysettings';
import { Struct } from '../../google/protobuf/struct';
import { Timestamp } from '../../google/protobuf/timestamp';
//
// message RequestContext {
// string studentID = 1;
// string app = 2;
// string sessionID = 3;
// google.protobuf.Timestamp timestamp = 4;
// }

/**
 * @generated from protobuf message sparxweb.ErrorMessage
 */
export interface ErrorMessage {
  /**
   * @generated from protobuf field: uint32 code = 1;
   */
  code: number;
  /**
   * @generated from protobuf field: string text = 2;
   */
  text: string;
}
/**
 * *
 * Data sent in a Login request to the server
 *
 * @generated from protobuf message sparxweb.LoginRequest
 */
export interface LoginRequest {
  /**
   * The school the student belongs to
   *
   * @generated from protobuf field: string school = 1;
   */
  school: string;
  /**
   * Student username
   *
   * @generated from protobuf field: string username = 2;
   */
  username: string;
  /**
   * Student password
   *
   * @generated from protobuf field: string password = 3;
   */
  password: string;
  /**
   * The app to connect to (currently "algenie" or "parentweb" I
   * think)
   *
   * @generated from protobuf field: string app = 4;
   */
  app: string;
  /**
   * The token used to connect. Can be empty if logging in with
   * username/password instead
   *
   * @generated from protobuf field: string token = 5;
   */
  token: string;
}
/**
 * *
 * Data sent as a response to a Login request to the server
 *
 * @generated from protobuf message sparxweb.LoginResponse
 */
export interface LoginResponse {
  /**
   * Sparx session data (include session id, etc)
   *
   * @generated from protobuf field: sparxweb.SparxSession session = 1;
   */
  session?: SparxSession;
  /**
   * Student data (includes name, etc)
   *
   * @generated from protobuf field: sparxweb.SparxStudent student = 2;
   */
  student?: SparxStudent;
}
/**
 * *
 * Request for details about a Sparx Web session, sent when Sparx Web first
 * loads. Passes the client feature flags, details of the client browser and
 * operating system and client version to be logged in analytics
 *
 * @generated from protobuf message sparxweb.SessionDetailsRequest
 */
export interface SessionDetailsRequest {
  /**
   * Timestamp from the client specifying when this request was made
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;
  /**
   * Client application name
   *
   * @generated from protobuf field: string applicationName = 8;
   */
  applicationName: string;
  /**
   * Client application version
   *
   * @generated from protobuf field: string applicationVersion = 2;
   */
  applicationVersion: string;
  /**
   * Client browser
   *
   * @generated from protobuf field: string browser = 3;
   */
  browser: string;
  /**
   * Client browser version
   *
   * @generated from protobuf field: string browserVersion = 4;
   */
  browserVersion: string;
  /**
   * Client operating system:
   *
   * @generated from protobuf field: string os = 5;
   */
  os: string;
  /**
   * Client operating system version:
   *
   * @generated from protobuf field: string osVersion = 6;
   */
  osVersion: string;
  /**
   * Deprecated: please use swserver session feature flags instead of passing from the client.
   * A set of feature flags (key-value pairs) used in the client, to be logged
   * in analytics
   *
   * @deprecated
   * @generated from protobuf field: map<string, string> clientFeatureFlags = 7 [deprecated = true];
   */
  clientFeatureFlags: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparxweb.SessionDetailsResponse
 */
export interface SessionDetailsResponse {
  /**
   * @generated from protobuf field: sparxweb.SparxSession session = 1;
   */
  session?: SparxSession;
  /**
   * @generated from protobuf field: sparxweb.SparxStudent student = 2;
   */
  student?: SparxStudent;
  /**
   * feature_flags are only populated for parent sessions
   *
   * @generated from protobuf field: google.protobuf.Struct feature_flags = 9;
   */
  featureFlags?: Struct;
}
/**
 * @generated from protobuf message sparxweb.JoinLessonRequest
 */
export interface JoinLessonRequest {
  /**
   * @generated from protobuf field: int32 joinCode = 1;
   */
  joinCode: number;
  /**
   * @generated from protobuf field: bool joinLast = 2;
   */
  joinLast: boolean;
  /**
   * Deprecated: please use swserver session feature flags instead of passing from the client.
   *
   * @deprecated
   * @generated from protobuf field: map<string, string> clientFeatureFlags = 3 [deprecated = true];
   */
  clientFeatureFlags: {
    [key: string]: string;
  };
  /**
   * A lesson delivery id can be specified instead of a join code if known. This
   * avoids looking it up
   *
   * @generated from protobuf field: string lessonDeliveryID = 4;
   */
  lessonDeliveryID: string;
}
/**
 * @generated from protobuf message sparxweb.JoinLessonResponse
 */
export interface JoinLessonResponse {
  /**
   * @generated from protobuf field: string lessonTitle = 1;
   */
  lessonTitle: string;
  /**
   * @generated from protobuf field: sparxweb.SparxSessionUpdate initialSessionUpdate = 2;
   */
  initialSessionUpdate?: SparxSessionUpdate;
  /**
   * @generated from protobuf field: lessondeliverysettings.LessonSettings lessonSettings = 3;
   */
  lessonSettings?: LessonSettings;
  /**
   * @generated from protobuf field: string lessonDeliveryID = 4;
   */
  lessonDeliveryID: string;
  /**
   * List of the ids of tutorials completed by the user
   *
   * @generated from protobuf field: repeated string tutorialsCompleted = 5;
   */
  tutorialsCompleted: string[];
  /**
   * @generated from protobuf field: int32 joinCode = 6;
   */
  joinCode: number;
}
/**
 * @generated from protobuf message sparxweb.LeaveLessonRequest
 */
export interface LeaveLessonRequest {}
/**
 * @generated from protobuf message sparxweb.LeaveLessonResponse
 */
export interface LeaveLessonResponse {}
/**
 * *
 * Data describing a session update (e.g. to pause / unpause a lesson)
 *
 * @generated from protobuf message sparxweb.SparxSessionUpdate
 */
export interface SparxSessionUpdate {
  /**
   * @generated from protobuf field: sparxweb.SparxSessionUpdate.StatusChange statusChange = 1;
   */
  statusChange: SparxSessionUpdate_StatusChange;
  /**
   * @generated from protobuf field: sparxweb.PackagePosition position = 2;
   */
  position?: PackagePosition;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 3;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: sparx.sparxweb.v1.VideoStatus videoStatus = 4;
   */
  videoStatus: VideoStatus;
}
/**
 * @generated from protobuf enum sparxweb.SparxSessionUpdate.StatusChange
 */
export enum SparxSessionUpdate_StatusChange {
  /**
   * @generated from protobuf enum value: NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: PAUSE = 1;
   */
  PAUSE = 1,
  /**
   * @generated from protobuf enum value: RESUME = 2;
   */
  RESUME = 2,
  /**
   * @generated from protobuf enum value: END = 3;
   */
  END = 3,
}
/**
 * *
 * Data describing a Sparx session
 *
 * @generated from protobuf message sparxweb.SparxSession
 */
export interface SparxSession {
  /**
   * ID of the session
   *
   * @generated from protobuf field: string sessionID = 1;
   */
  sessionID: string;
  /**
   * Current package / task / task item
   *
   * @generated from protobuf field: sparxweb.PackagePosition currentPackagePosition = 2;
   */
  currentPackagePosition?: PackagePosition;
  /**
   * The current activity details
   *
   * @generated from protobuf field: sparxweb.Activity currentActivity = 3;
   */
  currentActivity?: Activity;
  /**
   * The current appID
   *
   * @generated from protobuf field: string appID = 4;
   */
  appID: string;
  /**
   * login token for login without username/password
   *
   * @generated from protobuf field: string loginToken = 5;
   */
  loginToken: string;
  /**
   * List of the ids of tutorials completed by the user
   *
   * @generated from protobuf field: repeated string tutorialsCompleted = 6;
   */
  tutorialsCompleted: string[];
  /**
   * List of the ids of reward alerts that have been shown to and dismissed
   * by the user
   *
   * @generated from protobuf field: repeated string rewardAlertsDismissed = 7;
   */
  rewardAlertsDismissed: string[];
}
/**
 * *
 * Data describing a student
 *
 * @generated from protobuf message sparxweb.SparxStudent
 */
export interface SparxStudent {
  /**
   * Student's first name
   *
   * @generated from protobuf field: string firstName = 1;
   */
  firstName: string;
  /**
   * Student's last name
   *
   * @generated from protobuf field: string lastName = 2;
   */
  lastName: string;
  /**
   * The school the student is in.
   *
   * @generated from protobuf field: string schoolID = 13;
   */
  schoolID: string;
  /**
   * Awards the student currently has
   *
   * @generated from protobuf field: int32 xPoints = 3;
   */
  xPoints: number;
  /**
   * @generated from protobuf field: string studentID = 4;
   */
  studentID: string;
  /**
   * Sparx Levels currently awarded to the student
   *
   * @generated from protobuf field: repeated google.protobuf.Timestamp xLevels = 5;
   */
  xLevels: Timestamp[];
  /**
   * Sparx Thresholds are the thresholds for Sparx Levels
   *
   * @deprecated
   * @generated from protobuf field: repeated int32 xThresholds = 6 [deprecated = true];
   */
  xThresholds: number[];
  /**
   * The type of the student as a string:
   * Student/GuestTemplate/Guest/ClassroomGuest
   *
   * @generated from protobuf field: string studentType = 7;
   */
  studentType: string;
  /**
   * Flag to determine whether lessons are enabled for the student
   *
   * @generated from protobuf field: bool lessonsEnabled = 8;
   */
  lessonsEnabled: boolean;
  /**
   * Their last selected revision stream
   *
   * @generated from protobuf field: string revisionStream = 9;
   */
  revisionStream: string;
  /**
   * The types of session the student is allowed to log in to
   *
   * @generated from protobuf field: repeated sparxweb.SparxWebFeatureSet availableSessionTypes = 10;
   */
  availableSessionTypes: SparxWebFeatureSet[];
  /**
   * Flag to determine whether this student is a primary student
   *
   * @generated from protobuf field: bool isPrimaryStudent = 11;
   */
  isPrimaryStudent: boolean;
  /**
   * Whether the student has the tables games timer disabled
   *
   * @generated from protobuf field: bool disableTablesGamesTimer = 12;
   */
  disableTablesGamesTimer: boolean;
  /**
   * Labels associated with the student
   *
   * @generated from protobuf field: map<string, string> labels = 14;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * Whether bookwork checks are disabled for this student in student manager
   *
   * @generated from protobuf field: bool disableBookworkChecks = 15;
   */
  disableBookworkChecks: boolean;
}
/**
 * *
 * Data describing a position in a package
 *
 * @generated from protobuf message sparxweb.PackagePosition
 */
export interface PackagePosition {
  /**
   * ID of the package
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * Position of the task in the package (0 means not specified).  If
   * it is 0 then it means this position refers to the whole package
   *
   * @generated from protobuf field: int32 taskIndex = 2;
   */
  taskIndex: number;
  /**
   * Position of the task item in the task (0 means not specified).
   * If it is not 0 then taskIndex must be non-zero.  If it is 0 then
   * it means this position refers to the whole task (or the whole
   * package if taskIndex is also 0).
   *
   * @generated from protobuf field: int32 taskItemIndex = 3;
   */
  taskItemIndex: number;
  /**
   * State of the task. This can describe the stage that the task should
   * be delivered in. A zero value (STATE_UNSPECIFIED) is the default
   * state for a task and means normal delivery.
   *
   * @generated from protobuf field: sparx.sparxweb.v1.TaskState taskState = 4;
   */
  taskState: TaskState;
}
/**
 * *
 * Data describing an activity, which is an instance of a student
 * interacting with a question.
 *
 * @generated from protobuf message sparxweb.Activity
 */
export interface Activity {
  /**
   * Index for this activity
   *
   * @generated from protobuf field: int32 activityIndex = 1;
   */
  activityIndex: number;
  /**
   * The type of the activity
   *
   * @generated from protobuf field: sparxweb.ActivityType activityType = 2;
   */
  activityType: ActivityType;
  /**
   * @generated from protobuf oneof: payload
   */
  payload:
    | {
        oneofKind: 'question';
        /**
         * @generated from protobuf field: sparxweb.QuestionActivity question = 3;
         */
        question: QuestionActivity;
      }
    | {
        oneofKind: 'game';
        /**
         * @generated from protobuf field: sparx.games.state.v1.Init game = 4;
         */
        game: Init;
      }
    | {
        oneofKind: 'wac';
        /**
         * @generated from protobuf field: sparxweb.WACActivity wac = 5;
         */
        wac: WACActivity;
      }
    | {
        oneofKind: 'video';
        /**
         * @generated from protobuf field: sparxweb.VideoActivity video = 7;
         */
        video: VideoActivity;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * @generated from protobuf field: sparxweb.ActivityStatus status = 6;
   */
  status: ActivityStatus;
}
/**
 * @generated from protobuf message sparxweb.QuestionActivity
 */
export interface QuestionActivity {
  /**
   * Index for this question
   *
   * @generated from protobuf field: int32 questionIndex = 1;
   */
  questionIndex: number;
  /**
   * ID of the video for this activity (if there is one)
   *
   * @generated from protobuf field: string videoID = 2;
   */
  videoID: string;
  /**
   * Spec of the question (this is currently some JSON)
   *
   * @generated from protobuf field: string questionSpec = 3;
   */
  questionSpec: string;
  /**
   * Bookwork code for the activity (if there is one)
   *
   * @generated from protobuf field: string bookworkCode = 4;
   */
  bookworkCode: string;
  /**
   * Whether or not the WAC tutorial should be displayed
   *
   * @generated from protobuf field: bool displayWacTutorial = 5;
   */
  displayWacTutorial: boolean;
  /**
   * Skill this question belongs to, used for analytics on videos
   *
   * @generated from protobuf field: string skillID = 6;
   */
  skillID: string;
  /**
   * Skill this question belongs to, used for debug data on clicking task
   *
   * @generated from protobuf field: string questionID = 7;
   */
  questionID: string;
  /**
   * Whether this question could be multi part marked
   *
   * @generated from protobuf field: bool multiPartMarking = 8;
   */
  multiPartMarking: boolean;
}
/**
 * WACOption contains details about an answered question that can be used as a
 * choice when presenting multiple answers in a non-numeric WAC
 *
 * @generated from protobuf message sparxweb.WACOption
 */
export interface WACOption {
  /**
   * @generated from protobuf field: sparxweb.Answer wacedAnswer = 1;
   */
  wacedAnswer?: Answer;
  /**
   * @generated from protobuf field: string filledAnswerTemplate = 2;
   */
  filledAnswerTemplate: string;
}
/**
 * @generated from protobuf message sparxweb.WACActivity
 */
export interface WACActivity {
  /**
   * @generated from protobuf field: string questionSpec = 1;
   */
  questionSpec: string;
  /**
   * @generated from protobuf field: sparxweb.Answer wacedAnswer = 2;
   */
  wacedAnswer?: Answer;
  /**
   * @generated from protobuf field: repeated string choices = 3;
   */
  choices: string[];
  /**
   * @generated from protobuf field: string bookworkCode = 4;
   */
  bookworkCode: string;
  /**
   * @generated from protobuf field: sparxweb.TaskItem taskItem = 5;
   */
  taskItem?: TaskItem;
  /**
   * @generated from protobuf field: repeated sparxweb.WACOption options = 6;
   */
  options: WACOption[];
}
/**
 * @generated from protobuf message sparxweb.VideoActivity
 */
export interface VideoActivity {
  /**
   * Index for this question
   *
   * @generated from protobuf field: int32 questionIndex = 1;
   */
  questionIndex: number;
}
/**
 *
 * This specifies some amount awarded in a given currency.  Used in
 * SparxStudent message and in AnswerQuestionResponse.
 *
 * @generated from protobuf message sparxweb.Award
 */
export interface Award {
  /**
   * The id of the currency
   *
   * @generated from protobuf field: string currencyID = 1;
   */
  currencyID: string;
  /**
   * The amount awarded
   *
   * @generated from protobuf field: int32 amount = 2;
   */
  amount: number;
}
/**
 * *
 * Data sent in a JoinSparxSession request to the server
 *
 * @generated from protobuf message sparxweb.JoinSparxSessionRequest
 */
export interface JoinSparxSessionRequest {
  /**
   * ID of the session the client wants to attach to
   *
   * @generated from protobuf field: string sessionID = 1;
   */
  sessionID: string;
  /**
   * @generated from protobuf field: string studentID = 2;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string app = 3;
   */
  app: string;
}
/**
 * *
 * Data sent in a Logout request to the server
 *
 * @generated from protobuf message sparxweb.LogoutRequest
 */
export interface LogoutRequest {
  /**
   * ID of the session the client wants to detach from
   *
   * @generated from protobuf field: string SessionID = 1 [json_name = "SessionID"];
   */
  sessionID: string;
}
/**
 * *
 * Data sent as a response to a Logout request to the server
 *
 * @generated from protobuf message sparxweb.LogoutResponse
 */
export interface LogoutResponse {}
/**
 * *
 * Data sent in a GetPackageData request to the server
 *
 * @generated from protobuf message sparxweb.PackageDataRequest
 */
export interface PackageDataRequest {
  /**
   * If true, the response will include data for all the active
   * packages as well as the packages specified in packageIDs
   *
   * @generated from protobuf field: bool includeAllActivePackages = 2;
   */
  includeAllActivePackages: boolean;
  /**
   * If true, package level data will be returned for the specified
   * packages
   *
   * @generated from protobuf field: bool getPackages = 3;
   */
  getPackages: boolean;
  /**
   * If true, task level data will be returned for the specified
   * packages and task indexes or for all tasks in the specified
   * packages if no task indexes are specified
   *
   * @generated from protobuf field: bool getTasks = 4;
   */
  getTasks: boolean;
  /**
   * If true, task item level data will be returned for the specified
   * packages, tasks and task items or for all the specified tasks if
   * no task items are specified, or for all the specified packages
   * if no tasks and task items are specified
   *
   * @generated from protobuf field: bool getTaskItems = 5;
   */
  getTaskItems: boolean;
  /**
   * ID of the package to return data for
   *
   * @generated from protobuf field: string packageID = 6;
   */
  packageID: string;
  /**
   * Index of the task to return data for
   *
   * @generated from protobuf field: int64 taskIndex = 7;
   */
  taskIndex: number;
  /**
   * Index of the task items to return data for
   *
   * @generated from protobuf field: int64 taskItemIndex = 8;
   */
  taskItemIndex: number;
}
/**
 * *
 * This message contains data about packages, tasks and task items.
 * It is used as a response to a GetPackageData request to the server
 * or as the payload of and UpdatePackageData request to the client.
 *
 * @generated from protobuf message sparxweb.PackageData
 */
export interface PackageData {
  /**
   * Data about packages
   *
   * @generated from protobuf field: repeated sparxweb.Package packages = 1;
   */
  packages: Package[];
  /**
   * Data about tasks
   *
   * @generated from protobuf field: repeated sparxweb.Task tasks = 2;
   */
  tasks: Task[];
  /**
   * Data about task items
   *
   * @generated from protobuf field: repeated sparxweb.TaskItem taskItems = 3;
   */
  taskItems: TaskItem[];
}
/**
 * *
 * Data sent in a getFeedbackNotifications request to the server
 *
 * @generated from protobuf message sparxweb.FeedbackNotificationRequest
 */
export interface FeedbackNotificationRequest {}
/**
 * *
 * Response to FeedbackNotificationRequest
 *
 * @generated from protobuf message sparxweb.FeedbackNotificationResponse
 */
export interface FeedbackNotificationResponse {
  /**
   * @generated from protobuf field: repeated sparxweb.FeedbackNotification notifications = 1;
   */
  notifications: FeedbackNotification[];
}
/**
 * *
 * Data about a feedback notification
 *
 * @generated from protobuf message sparxweb.FeedbackNotification
 */
export interface FeedbackNotification {
  /**
   * @generated from protobuf field: string notificationID = 1;
   */
  notificationID: string;
  /**
   * @generated from protobuf field: string studentID = 2;
   */
  studentID: string;
  /**
   * Content of the notification
   *
   * @generated from protobuf field: string content = 3;
   */
  content: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTime = 4;
   */
  startTime?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endTime = 5;
   */
  endTime?: Timestamp;
  /**
   * E.g. HWSUMMARY, SKILLHELP
   *
   * @generated from protobuf field: string feedbackType = 6;
   */
  feedbackType: string;
  /**
   * @generated from protobuf field: string recipientType = 7;
   */
  recipientType: string;
}
/**
 * *
 * Getter/Setter for email subscription status for the
 * relationship between the student and the passed parent.
 * Will error if this relationship does not exist.
 *
 * @generated from protobuf message sparxweb.GetParentEmailSubscriptionStatusRequest
 */
export interface GetParentEmailSubscriptionStatusRequest {
  /**
   * @generated from protobuf field: string parentID = 1;
   */
  parentID: string;
}
/**
 * @generated from protobuf message sparxweb.GetParentEmailSubscriptionStatusResponse
 */
export interface GetParentEmailSubscriptionStatusResponse {
  /**
   * @generated from protobuf field: bool subscribed = 1;
   */
  subscribed: boolean;
}
/**
 * @generated from protobuf message sparxweb.SetParentEmailSubscriptionStatusRequest
 */
export interface SetParentEmailSubscriptionStatusRequest {
  /**
   * @generated from protobuf field: string parentID = 1;
   */
  parentID: string;
  /**
   * @generated from protobuf field: bool subscribed = 2;
   */
  subscribed: boolean;
}
/**
 * @generated from protobuf message sparxweb.SetParentEmailSubscriptionStatusResponse
 */
export interface SetParentEmailSubscriptionStatusResponse {
  /**
   * @generated from protobuf field: bool subscribed = 1;
   */
  subscribed: boolean;
}
/**
 * *
 * Request and response used by parent portal to get the student's last login at timestamp
 *
 * @generated from protobuf message sparxweb.GetStudentLastLoginAtRequest
 */
export interface GetStudentLastLoginAtRequest {}
/**
 * @generated from protobuf message sparxweb.GetStudentLastLoginAtResponse
 */
export interface GetStudentLastLoginAtResponse {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastLoginAt = 1;
   */
  lastLoginAt?: Timestamp;
}
/**
 * *
 * Data about a package
 *
 * @generated from protobuf message sparxweb.Package
 */
export interface Package {
  /**
   * ID of the package
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * First day the package is active
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 2;
   */
  startDate?: Timestamp;
  /**
   * Last day the package is active
   *
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 3;
   */
  endDate?: Timestamp;
  /**
   * Package title (to be displayed)
   *
   * @generated from protobuf field: string title = 4;
   */
  title: string;
  /**
   * Package type (e.g. "homework", "lesson" or "revision")
   *
   * @generated from protobuf field: string packageType = 5;
   */
  packageType: string;
  /**
   * Number of tasks in the package
   *
   * @generated from protobuf field: int32 numTasks = 6;
   */
  numTasks: number;
  /**
   * Total number of task items in the package
   *
   * @generated from protobuf field: int32 numTaskItems = 7;
   */
  numTaskItems: number;
  /**
   * Total number of task items which have been done for this package
   *
   * @generated from protobuf field: int32 numTaskItemsDone = 8;
   */
  numTaskItemsDone: number;
  /**
   * This is true if the package has been complete in the past
   * (i.e. numTaskItemsDone was equal to numTaskItems)
   *
   * @generated from protobuf field: bool hasBeenComplete = 9;
   */
  hasBeenComplete: boolean;
  /**
   * @generated from protobuf field: int32 numTasksComplete = 10;
   */
  numTasksComplete: number;
  /**
   * @generated from protobuf field: int32 numTasksInProgress = 11;
   */
  numTasksInProgress: number;
  /**
   * The status of the package generation
   * It can be PLACEHOLDER, CONTENTS_GENERATED or GENERATION_FAILED
   * (see PackageStatus in homeworkgen.proto)
   *
   * @generated from protobuf field: string status = 12;
   */
  status: string;
  /**
   * The number of xPoints student was awarded for this package
   *
   * @generated from protobuf field: int32 xPoints = 13;
   */
  xPoints: number;
  /**
   * The completion on this package
   *
   * @generated from protobuf field: sparxweb.Completion completion = 14;
   */
  completion?: Completion;
  /**
   * Deprecated. Diagnostic packages are no longer stored in BigQuery via msgelephant.
   * A list of the task IDs in the package. This is set in diagnostic homework
   * where the task IDs are not necessarily consecutive.
   *
   * @generated from protobuf field: repeated string taskIds = 15;
   */
  taskIds: string[];
  /**
   * Package labels
   *
   * @generated from protobuf field: map<string, string> labels = 16;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * The time that the homework was deleted. If the homework hasn't been deleted, this will be null.
   *
   * @generated from protobuf field: google.protobuf.Timestamp deletedTime = 17;
   */
  deletedTime?: Timestamp;
  /**
   * The ID of the homework that this package is part of
   *
   * @generated from protobuf field: string homeworkID = 18;
   */
  homeworkID: string;
  /**
   * The count of swaps made in this package
   *
   * @generated from protobuf field: int32 numSwapsMade = 19;
   */
  numSwapsMade: number;
}
/**
 *
 * Data about a task
 *
 * @generated from protobuf message sparxweb.Task
 */
export interface Task {
  /**
   * ID of the package the task belongs to
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * Position of the task in the package
   *
   * @generated from protobuf field: int32 taskIndex = 2;
   */
  taskIndex: number;
  /**
   * Task title (to be displayed)
   *
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * Task type (e.g. "CORE", "EXTENSION", "ACTIVITY")
   *
   * @generated from protobuf field: string taskType = 4;
   */
  taskType: string;
  /**
   * Total number of task items in the task
   *
   * @generated from protobuf field: int32 numTaskItems = 6;
   */
  numTaskItems: number;
  /**
   * Number of task items which are done in this task
   *
   * @generated from protobuf field: int32 numTaskItemsDone = 7;
   */
  numTaskItemsDone: number;
  /**
   * True if the task has been complete in the past
   * (i.e. numTaskItemsDone was equal to numTaskItems)
   *
   * @generated from protobuf field: bool hasBeenComplete = 8;
   */
  hasBeenComplete: boolean;
  /**
   * @generated from protobuf field: int32 numTaskItemsWrong = 9;
   */
  numTaskItemsWrong: number;
  /**
   * Prevent WACs on this task.
   *
   * @deprecated
   * @generated from protobuf field: bool doNotWAC = 10 [deprecated = true];
   */
  doNotWAC: boolean;
  /**
   * The completion on this task
   *
   * @generated from protobuf field: sparxweb.Completion completion = 11;
   */
  completion?: Completion;
  /**
   * The completion store on this task in a json blob
   *
   * @generated from protobuf field: string completionStore = 12;
   */
  completionStore: string;
  /**
   * Task labels
   *
   * @generated from protobuf field: map<string, string> labels = 13;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * Fixed Question Delivery, task items in this task are delivered with FQD
   * meaning we need to use different wording around WACs.
   *
   * @generated from protobuf field: bool fixedQuestionDelivery = 14;
   */
  fixedQuestionDelivery: boolean;
  /**
   * Second Chance Delivery, task items in the task are delivered with SCD
   * this enables different wording around WACs and other tutorials.
   *
   * @generated from protobuf field: bool secondChanceDelivery = 15;
   */
  secondChanceDelivery: boolean;
}
/**
 * *
 * Data about a task item
 *
 * @generated from protobuf message sparxweb.TaskItem
 */
export interface TaskItem {
  /**
   * ID of the package the task item belongs to
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * Position of the task in the package the task item belongs to
   *
   * @generated from protobuf field: int32 taskIndex = 2;
   */
  taskIndex: number;
  /**
   * Position of the task item in its task
   *
   * @generated from protobuf field: int32 taskItemIndex = 3;
   */
  taskItemIndex: number;
  /**
   * Current status of the task item
   *
   * @generated from protobuf field: sparxweb.TaskItemStatus status = 4;
   */
  status: TaskItemStatus;
  /**
   * Number of times this task item has been answered correctly
   *
   * @generated from protobuf field: int32 numCorrectAnswers = 5;
   */
  numCorrectAnswers: number;
  /**
   * Number of times this task item has been answered incorrectly
   *
   * @generated from protobuf field: int32 numWrongAnswers = 6;
   */
  numWrongAnswers: number;
  /**
   * Number of times the video for this task item has been watched
   *
   * @generated from protobuf field: int32 numVideoWatched = 7;
   */
  numVideoWatched: number;
  /**
   * When this task item was last answered
   *
   * @generated from protobuf field: google.protobuf.Timestamp lastAnsweredAt = 8;
   */
  lastAnsweredAt?: Timestamp;
  /**
   * @generated from protobuf field: string name = 9;
   */
  name: string;
  /**
   * TODO: Deprecate once all servers send SEEKHELP status.
   * Identifies to the client that the version of swworker
   * it is connected to can calculate the seek help status.
   *
   * @generated from protobuf field: bool useStatus = 15;
   */
  useStatus: boolean;
  /**
   * Any submitted rating on this task item.
   *
   * @generated from protobuf field: sparxweb.Rating rating = 16;
   */
  rating?: Rating;
  /**
   * Number of times this task item has been answered incorrectly in a row
   *
   * @generated from protobuf field: int32 numConsecutiveWrongAnswers = 17;
   */
  numConsecutiveWrongAnswers: number;
  /**
   * Map of labels about this task item containing additional metadata.
   * An example includes 'ftq_item' used by fast track quiz tasks to identify different types of item.
   *
   * @generated from protobuf field: map<string, string> labels = 18;
   */
  labels: {
    [key: string]: string;
  };
  /**
   * Number of questions in the task item skill
   *
   * @generated from protobuf field: int32 numSkillQuestions = 19;
   */
  numSkillQuestions: number;
  /**
   * If this task item was swapped in for another task item, this will be the index of the task
   * item it was swapped in for. 0 implies it wasn't swapped in.
   *
   * @generated from protobuf field: int32 swappedInForIndex = 20;
   */
  swappedInForIndex: number;
  /**
   * Whether the video has been played for this task item
   * (0 or 1 so that it can be included in the package and task completion)
   *
   * @generated from protobuf field: int32 videoPlayed = 21;
   */
  videoPlayed: number;
  /**
   * The cumulative time that the video modal has been open for this task item
   *
   * @generated from protobuf field: float videoOpenDuration = 22;
   */
  videoOpenDuration: number;
}
/**
 * *
 * Data sent in a GetQuestion request to the server
 *
 * Session id for the request
 * string sessionID = 1;
 *
 * @generated from protobuf message sparxweb.GetActivityRequest
 */
export interface GetActivityRequest {
  /**
   * @generated from protobuf field: sparxweb.ActivityType activityType = 1;
   */
  activityType: ActivityType;
  /**
   * Task item we want a question for
   *
   * @generated from protobuf field: sparxweb.PackagePosition taskItem = 2;
   */
  taskItem?: PackagePosition;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 4;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf oneof: payload
   */
  payload:
    | {
        oneofKind: 'gameID';
        /**
         * @generated from protobuf field: string gameID = 3;
         */
        gameID: string;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * Method to get the activity
   *
   * @generated from protobuf field: sparxweb.GetActivityRequest.Method method = 5;
   */
  method: GetActivityRequest_Method;
  /**
   * Deprecated: Please use swserver session feature flags instead
   *
   * @deprecated
   * @generated from protobuf field: map<string, string> clientFeatureFlags = 6 [deprecated = true];
   */
  clientFeatureFlags: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf enum sparxweb.GetActivityRequest.Method
 */
export enum GetActivityRequest_Method {
  /**
   * Get a new activity for this task item
   *
   * @generated from protobuf enum value: NEW = 0;
   */
  NEW = 0,
  /**
   * Resume the last activity for this task item if it is in a resumable
   * state or get a new one
   *
   * @generated from protobuf enum value: RESUMABLE = 1;
   */
  RESUMABLE = 1,
  /**
   * Retrieve the last activity for this task item whatever state it
   * is in. If there is no last one then return an error
   *
   * @generated from protobuf enum value: LAST = 2;
   */
  LAST = 2,
}
/**
 * *
 * Events can be acked immediately before they are processed
 *
 * @generated from protobuf message sparxweb.Ack
 */
export interface Ack {}
/**
 * @generated from protobuf message sparxweb.ActivityAction
 */
export interface ActivityAction {
  /**
   * @generated from protobuf field: int32 activityIndex = 1;
   */
  activityIndex: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'video';
        /**
         * @generated from protobuf field: sparxweb.VideoAction video = 3;
         */
        video: VideoAction;
      }
    | {
        oneofKind: 'question';
        /**
         * @generated from protobuf field: sparxweb.QuestionAction question = 4;
         */
        question: QuestionAction;
      }
    | {
        oneofKind: 'game';
        /**
         * @generated from protobuf field: sparx.games.state.v1.Action game = 5;
         */
        game: Action;
      }
    | {
        oneofKind: 'wac';
        /**
         * @generated from protobuf field: sparxweb.WACAction wac = 6;
         */
        wac: WACAction;
      }
    | {
        oneofKind: 'quiz';
        /**
         * @generated from protobuf field: sparxweb.QuizAction quiz = 7;
         */
        quiz: QuizAction;
      }
    | {
        oneofKind: 'videoQuestion';
        /**
         * @generated from protobuf field: sparxweb.VideoQuestionAction video_question = 8;
         */
        videoQuestion: VideoQuestionAction;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparxweb.VideoAction
 */
export interface VideoAction {
  /**
   * @generated from protobuf field: string videoID = 1;
   */
  videoID: string;
  /**
   * @generated from protobuf field: sparxweb.VideoAction.ActionType actionType = 2;
   */
  actionType: VideoAction_ActionType;
  /**
   * @generated from protobuf field: string watchID = 3;
   */
  watchID: string;
}
/**
 * @generated from protobuf enum sparxweb.VideoAction.ActionType
 */
export enum VideoAction_ActionType {
  /**
   * @generated from protobuf enum value: VIEW = 0;
   */
  VIEW = 0,
  /**
   * @generated from protobuf enum value: PLAY = 1;
   */
  PLAY = 1,
  /**
   * @generated from protobuf enum value: PAUSE = 2;
   */
  PAUSE = 2,
  /**
   * @generated from protobuf enum value: DISMISS = 3;
   */
  DISMISS = 3,
}
/**
 * @generated from protobuf message sparxweb.QuestionAction
 */
export interface QuestionAction {
  /**
   * @generated from protobuf field: int32 questionIndex = 1;
   */
  questionIndex: number;
  /**
   * @generated from protobuf field: sparxweb.QuestionAction.ActionType actionType = 3;
   */
  actionType: QuestionAction_ActionType;
  /**
   * @generated from protobuf field: sparxweb.Answer answer = 4;
   */
  answer?: Answer;
  /**
   * @generated from protobuf field: sparxweb.Rating rating = 5;
   */
  rating?: Rating;
}
/**
 * @generated from protobuf enum sparxweb.QuestionAction.ActionType
 */
export enum QuestionAction_ActionType {
  /**
   * @generated from protobuf enum value: VIEW = 0;
   */
  VIEW = 0,
  /**
   * @generated from protobuf enum value: ANSWER = 1;
   */
  ANSWER = 1,
  /**
   * @generated from protobuf enum value: DISMISS = 2;
   */
  DISMISS = 2,
  /**
   * @generated from protobuf enum value: PAUSE = 3;
   */
  PAUSE = 3,
  /**
   * @generated from protobuf enum value: RATE = 4;
   */
  RATE = 4,
}
/**
 * @generated from protobuf message sparxweb.QuizAction
 */
export interface QuizAction {
  /**
   * @generated from protobuf field: int32 questionIndex = 1;
   */
  questionIndex: number;
  /**
   * @generated from protobuf field: sparxweb.QuizAction.ActionType actionType = 2;
   */
  actionType: QuizAction_ActionType;
  /**
   * @generated from protobuf field: sparxweb.Answer answer = 3;
   */
  answer?: Answer;
}
/**
 * @generated from protobuf enum sparxweb.QuizAction.ActionType
 */
export enum QuizAction_ActionType {
  /**
   * @generated from protobuf enum value: VIEW = 0;
   */
  VIEW = 0,
  /**
   * @generated from protobuf enum value: PAUSE = 1;
   */
  PAUSE = 1,
  /**
   * @generated from protobuf enum value: SUBMIT_QUIZ_ANSWER = 2;
   */
  SUBMIT_QUIZ_ANSWER = 2,
  /**
   * @generated from protobuf enum value: STORE_ANSWER = 3;
   */
  STORE_ANSWER = 3,
  /**
   * @generated from protobuf enum value: DISMISS = 4;
   */
  DISMISS = 4,
}
/**
 * @generated from protobuf message sparxweb.WACAction
 */
export interface WACAction {
  /**
   * @generated from protobuf field: sparxweb.WACAction.ActionType actionType = 3;
   */
  actionType: WACAction_ActionType;
  /**
   * @generated from protobuf field: sparxweb.Answer answer = 4;
   */
  answer?: Answer;
  /**
   * @generated from protobuf field: map<string, string> extraData = 5;
   */
  extraData: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf enum sparxweb.WACAction.ActionType
 */
export enum WACAction_ActionType {
  /**
   * @generated from protobuf enum value: VIEW = 0;
   */
  VIEW = 0,
  /**
   * @generated from protobuf enum value: ANSWER = 1;
   */
  ANSWER = 1,
  /**
   * @generated from protobuf enum value: DISMISS = 2;
   */
  DISMISS = 2,
}
/**
 * @generated from protobuf message sparxweb.VideoQuestionAction
 */
export interface VideoQuestionAction {
  /**
   * @generated from protobuf field: int32 questionIndex = 1;
   */
  questionIndex: number;
  /**
   * @generated from protobuf field: sparxweb.VideoQuestionAction.ActionType actionType = 2;
   */
  actionType: VideoQuestionAction_ActionType;
}
/**
 * @generated from protobuf enum sparxweb.VideoQuestionAction.ActionType
 */
export enum VideoQuestionAction_ActionType {
  /**
   * @generated from protobuf enum value: VIEW = 0;
   */
  VIEW = 0,
  /**
   * @generated from protobuf enum value: COMPLETE = 1;
   */
  COMPLETE = 1,
  /**
   * @generated from protobuf enum value: DISMISS = 2;
   */
  DISMISS = 2,
}
/**
 * @generated from protobuf message sparxweb.Answer
 */
export interface Answer {
  /**
   * @generated from protobuf field: repeated answer.AnswerComponent components = 1;
   */
  components: AnswerComponent[];
  /**
   * @generated from protobuf field: string hash = 2;
   */
  hash: string;
}
/**
 * @generated from protobuf message sparxweb.Rating
 */
export interface Rating {
  /**
   * @generated from protobuf field: int32 confidence = 1;
   */
  confidence: number;
  /**
   * Second confidence rating (first is original)
   *
   * @generated from protobuf field: int32 confidenceAfter = 2;
   */
  confidenceAfter: number;
}
/**
 * @generated from protobuf message sparxweb.Reward
 */
export interface Reward {
  /**
   * @generated from protobuf field: int32 xPoints = 1;
   */
  xPoints: number;
  /**
   * @generated from protobuf field: string reason = 2;
   */
  reason: string;
}
/**
 * @generated from protobuf message sparxweb.RewardGroup
 */
export interface RewardGroup {
  /**
   * the type of rewards in the group (e.g. task, package etc)
   *
   * @generated from protobuf field: sparxweb.RewardGroup.Type type = 1;
   */
  type: RewardGroup_Type;
  /**
   * the rewards given
   *
   * @generated from protobuf field: repeated sparxweb.Reward rewards = 2;
   */
  rewards: Reward[];
  /**
   * the xp state of the student after applying all rewards in the group
   *
   * @generated from protobuf field: sparxweb.StudentRewards xp_state_after_applying = 3;
   */
  xpStateAfterApplying?: StudentRewards;
}
/**
 * @generated from protobuf enum sparxweb.RewardGroup.Type
 */
export enum RewardGroup_Type {
  /**
   * @generated from protobuf enum value: NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: TASK = 1;
   */
  TASK = 1,
  /**
   * @generated from protobuf enum value: PACKAGE = 2;
   */
  PACKAGE = 2,
  /**
   * @generated from protobuf enum value: TIME_TABLES = 3;
   */
  TIME_TABLES = 3,
}
/**
 * @generated from protobuf message sparxweb.ActivityActionResponse
 */
export interface ActivityActionResponse {
  /**
   * True if the answer submitted was correct
   *
   * @generated from protobuf field: bool success = 1;
   */
  success: boolean;
  /**
   * True if this action makes the activity complete
   *
   * @generated from protobuf field: string status = 2;
   */
  status: string;
  /**
   * True if in consecutive mode and another WAC due
   *
   * @generated from protobuf field: bool consecutiveWAC = 3;
   */
  consecutiveWAC: boolean;
  /**
   * List of awards given as a result of completing the activity
   * (usually when it has made the task complete)
   * deprecated: use rewardGroups instead
   *
   * @deprecated
   * @generated from protobuf field: repeated sparxweb.Reward rewards = 4 [deprecated = true];
   */
  rewards: Reward[];
  /**
   * @generated from protobuf oneof: update
   */
  update:
    | {
        oneofKind: 'gameUpdate';
        /**
         * @generated from protobuf field: sparx.games.state.v1.Update gameUpdate = 5;
         */
        gameUpdate: Update;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * The XMl answer template filled with the given answer for the activity
   *
   * @generated from protobuf field: string givenAnswerXML = 6;
   */
  givenAnswerXML: string;
  /**
   * @generated from protobuf field: sparxweb.TaskItemStatus taskItemStatus = 7;
   */
  taskItemStatus: TaskItemStatus;
  /**
   * map of answer part ids to their corresponding gap evaluation
   *
   * @generated from protobuf field: map<string, sparxweb.GapEvaluation> gap_evaluations = 8;
   */
  gapEvaluations: {
    [key: string]: GapEvaluation;
  };
  /**
   * map of input refs to hints
   *
   * @generated from protobuf field: map<string, sparxweb.Hint> hints = 9;
   */
  hints: {
    [key: string]: Hint;
  };
  /**
   * ordered list of grouped xp rewards for the activity
   *
   * @generated from protobuf field: repeated sparxweb.RewardGroup rewardGroups = 10;
   */
  rewardGroups: RewardGroup[];
  /**
   * number of hints given in this activity
   *
   * @generated from protobuf field: int32 numHintsGiven = 11;
   */
  numHintsGiven: number;
}
/**
 * Evaluation of an answer part of the delivered question
 *
 * @generated from protobuf message sparxweb.GapEvaluation
 */
export interface GapEvaluation {
  /**
   * If gap was evaluated as correct.
   *
   * @generated from protobuf field: bool correct = 1;
   */
  correct: boolean;
}
/**
 * @generated from protobuf message sparxweb.AssetsTokenRequest
 */
export interface AssetsTokenRequest {}
/**
 * @generated from protobuf message sparxweb.AssetsTokenResponse
 */
export interface AssetsTokenResponse {
  /**
   * @generated from protobuf field: string token = 1;
   */
  token: string;
  /**
   * @generated from protobuf field: string assetServerURL = 2;
   */
  assetServerURL: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp validFrom = 3;
   */
  validFrom?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp expiresAt = 4;
   */
  expiresAt?: Timestamp;
}
/**
 * @generated from protobuf message sparxweb.ThrottledErrorDetails
 */
export interface ThrottledErrorDetails {
  /**
   * @generated from protobuf field: int32 delay = 1;
   */
  delay: number;
}
/**
 * @generated from protobuf message sparxweb.UserActive
 */
export interface UserActive {}
/**
 * @generated from protobuf message sparxweb.DebugInfoRequest
 */
export interface DebugInfoRequest {
  /**
   * @generated from protobuf field: int32 activityIndex = 1;
   */
  activityIndex: number;
}
/**
 * @generated from protobuf message sparxweb.DebugInfoResponse
 */
export interface DebugInfoResponse {
  /**
   * @generated from protobuf field: repeated sparxweb.DebugInfoResponse.DebugInfo info = 1;
   */
  info: DebugInfoResponse_DebugInfo[];
}
/**
 * @generated from protobuf message sparxweb.DebugInfoResponse.DebugInfo
 */
export interface DebugInfoResponse_DebugInfo {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: string value = 2;
   */
  value: string;
}
/**
 * Request to update a user's tutorial status
 *
 * @generated from protobuf message sparxweb.UpdateTutorialsRequest
 */
export interface UpdateTutorialsRequest {
  /**
   * Array of the ids of the tutorials that the user has completed
   *
   * @generated from protobuf field: repeated string tutorialsCompleted = 1;
   */
  tutorialsCompleted: string[];
}
/**
 * Called by client when they dismiss a "you got a reward" alert
 * Causes the sent list of alerts to be appended to the existing "dismissed
 * alerts" list in the user state
 *
 * @generated from protobuf message sparxweb.UpdateRewardAlertsRequest
 */
export interface UpdateRewardAlertsRequest {
  /**
   * @generated from protobuf field: repeated string rewardAlertsDismissed = 1;
   */
  rewardAlertsDismissed: string[];
}
//
// Messages for getting data from game progress and writing data to game
// progress

/**
 * Called by client to request data from the game progress store
 * TODO: This endpoint and message has moved to sparx.games.v1.ProgressStore/GetProgress.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.GetGameProgressRequest
 */
export interface GetGameProgressRequest {
  /**
   * @generated from protobuf field: string dataId = 1;
   */
  dataId: string;
  /**
   * @generated from protobuf field: string questionSet = 2;
   */
  questionSet: string;
}
/**
 * Returned to client with the requested progress data
 * TODO: This endpoint has moved to sparx.games.v1.ProgressStore/GetProgress.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.GetGameProgressResponse
 */
export interface GetGameProgressResponse {
  /**
   * @generated from protobuf field: sparx.games.store.v1.Store storedData = 1;
   */
  storedData?: Store;
}
/**
 * Called by client to modify the data in the game progress store
 * TODO: This endpoint has moved to sparx.games.v1.ProgressStore/UpdateProgress.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.UpdateGameProgressRequest
 */
export interface UpdateGameProgressRequest {
  /**
   * @generated from protobuf field: string dataId = 1;
   */
  dataId: string;
  /**
   * @generated from protobuf field: string questionSet = 4;
   */
  questionSet: string;
  /**
   * @generated from protobuf field: sparx.games.store.v1.Store updatedData = 2;
   */
  updatedData?: Store;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 3;
   */
  timestamp?: Timestamp;
}
/**
 * Returned to client when that modification has been completed
 * TODO: This endpoint has moved to sparx.games.v1.ProgressStore/UpdateProgress.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.UpdateGameProgressResponse
 */
export interface UpdateGameProgressResponse {
  /**
   * @generated from protobuf field: map<string, sparxweb.GameProgressUpdateState> updateStates = 1;
   */
  updateStates: {
    [key: string]: GameProgressUpdateState;
  };
}
/**
 * @generated from protobuf message sparxweb.GameProgressUpdateState
 */
export interface GameProgressUpdateState {
  /**
   * @generated from protobuf field: bool success = 1;
   */
  success: boolean;
  /**
   * @generated from protobuf field: string reason = 2;
   */
  reason: string;
}
/**
 * Request which prompts the server to give the student XP for
 * skipping a game. Responds with a Reward message detailing the XP
 *
 * @generated from protobuf message sparxweb.SkipGameWithXPRequest
 */
export interface SkipGameWithXPRequest {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;
}
/**
 * Request which provides insights information
 *
 * @generated from protobuf message sparxweb.StudentInsightsRequest
 */
export interface StudentInsightsRequest {}
/**
 * Response to PackageHistoryRequest which provides a summary of number of task
 * items and packages the
 * / student has ever completed, for student insights.
 *
 * @generated from protobuf message sparxweb.PackageHistoryResponse
 */
export interface PackageHistoryResponse {
  /**
   * Counts for number of packages of each type completed by the student
   *
   * @generated from protobuf field: map<string, int32> packages = 1;
   */
  packages: {
    [key: string]: number;
  };
  /**
   * Counts for number of task items completed in packages of each type
   *
   * @generated from protobuf field: map<string, int32> taskItems = 2;
   */
  taskItems: {
    [key: string]: number;
  };
}
/**
 * @generated from protobuf message sparxweb.PracticeHomeworksResponse
 */
export interface PracticeHomeworksResponse {
  /**
   * @generated from protobuf field: sparxweb.Task nextOptionalTask = 1;
   */
  nextOptionalTask?: Task;
  /**
   * @generated from protobuf field: sparxweb.Task nextTargetTask = 2;
   */
  nextTargetTask?: Task;
  /**
   * @generated from protobuf field: string nextTargetVideoID = 3;
   */
  nextTargetVideoID: string;
}
/**
 * Details of which topic substrand a student has learnt most/least of for
 * student insights
 *
 * @generated from protobuf message sparxweb.HowMuchLearntResponse
 */
export interface HowMuchLearntResponse {
  /**
   * @generated from protobuf field: string mostLearntSubstrand = 1;
   */
  mostLearntSubstrand: string;
  /**
   * @generated from protobuf field: string leastLearntSubstrand = 2;
   */
  leastLearntSubstrand: string;
  /**
   * @generated from protobuf field: map<string, sparxweb.HowMuchLearntResponse.StrandStatus> strands = 3;
   */
  strands: {
    [key: string]: HowMuchLearntResponse_StrandStatus;
  };
}
/**
 * @generated from protobuf enum sparxweb.HowMuchLearntResponse.StrandStatus
 */
export enum HowMuchLearntResponse_StrandStatus {
  /**
   * @generated from protobuf enum value: ON_TRACK = 0;
   */
  ON_TRACK = 0,
  /**
   * @generated from protobuf enum value: FOCUS_AREA = 1;
   */
  FOCUS_AREA = 1,
  /**
   * @generated from protobuf enum value: UNAVAILABLE = 2;
   */
  UNAVAILABLE = 2,
}
/**
 * @generated from protobuf message sparxweb.StudentInsightsResponse
 */
export interface StudentInsightsResponse {
  /**
   * @generated from protobuf field: sparxweb.PackageHistoryResponse packageHistory = 1;
   */
  packageHistory?: PackageHistoryResponse;
  /**
   * @generated from protobuf field: sparxweb.PracticeHomeworksResponse practice = 2;
   */
  practice?: PracticeHomeworksResponse;
  /**
   * @deprecated
   * @generated from protobuf field: sparxweb.HowMuchLearntResponse howMuchLearnt = 3 [deprecated = true];
   */
  howMuchLearnt?: HowMuchLearntResponse;
}
/**
 * @generated from protobuf message sparxweb.GetLeagueResultsRequest
 */
export interface GetLeagueResultsRequest {
  /**
   * Game to load the results for.
   *
   * @generated from protobuf field: string gameID = 1;
   */
  gameID: string;
  /**
   * Minimum time results was awarded (inclusive). Can be excluded to be the
   * beginning of time.
   *
   * @generated from protobuf field: google.protobuf.Timestamp startTimestamp = 2;
   */
  startTimestamp?: Timestamp;
  /**
   * Maximum time result was awarded (exclusive). Can be excluded to be now.
   *
   * @generated from protobuf field: google.protobuf.Timestamp endTimestamp = 3;
   */
  endTimestamp?: Timestamp;
  /**
   * The earliest time we can look back to when determining medals, in terms
   * of ISO indexed week and year
   *
   * @generated from protobuf field: sparx.games.leagueman.v1.WeekIndex medalStartWeekIndex = 4;
   */
  medalStartWeekIndex?: WeekIndex;
}
/**
 * @generated from protobuf message sparxweb.GetLeagueResultsResponse
 */
export interface GetLeagueResultsResponse {
  /**
   * @generated from protobuf field: repeated sparx.games.leagueman.v1.Result results = 1;
   */
  results: Result[];
  /**
   * @generated from protobuf field: repeated sparx.games.leagueman.v1.Medal medals = 2;
   */
  medals: Medal[];
}
/**
 * @generated from protobuf message sparxweb.SaveLeagueGameScoreRequest
 */
export interface SaveLeagueGameScoreRequest {
  /**
   * @generated from protobuf field: sparx.games.leagueman.v1.Result result = 1;
   */
  result?: Result;
}
/**
 * @generated from protobuf message sparxweb.SaveLeagueGameScoreResponse
 */
export interface SaveLeagueGameScoreResponse {}
/**
 * @generated from protobuf message sparxweb.GetLeagueAvatarRequest
 */
export interface GetLeagueAvatarRequest {}
/**
 * @generated from protobuf message sparxweb.GetLeagueAvatarResponse
 */
export interface GetLeagueAvatarResponse {
  /**
   * @generated from protobuf field: sparx.games.leagueman.v1.Avatar avatar = 1;
   */
  avatar?: Avatar;
}
/**
 * @generated from protobuf message sparxweb.SetLeagueAvatarRequest
 */
export interface SetLeagueAvatarRequest {
  /**
   * @generated from protobuf field: sparx.games.leagueman.v1.Avatar avatar = 1;
   */
  avatar?: Avatar;
}
/**
 * @generated from protobuf message sparxweb.GetLeagueMedalsRequest
 */
export interface GetLeagueMedalsRequest {
  /**
   * @generated from protobuf field: string gameID = 1;
   */
  gameID: string;
  /**
   * The earliest time we can look back to when determining medals, in terms
   * of ISO indexed week and year
   *
   * @generated from protobuf field: sparx.games.leagueman.v1.WeekIndex medalStartWeekIndex = 4;
   */
  medalStartWeekIndex?: WeekIndex;
}
/**
 * @generated from protobuf message sparxweb.GetLeagueMedalsResponse
 */
export interface GetLeagueMedalsResponse {
  /**
   * @generated from protobuf field: repeated sparx.games.leagueman.v1.Medal medals = 1;
   */
  medals: Medal[];
}
/**
 * @generated from protobuf message sparxweb.SetLeagueAvatarResponse
 */
export interface SetLeagueAvatarResponse {}
/**
 * @generated from protobuf message sparxweb.SubmitQuizAnswersRequest
 */
export interface SubmitQuizAnswersRequest {
  /**
   * @generated from protobuf field: sparxweb.PackagePosition task = 1;
   */
  task?: PackagePosition;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: bool skipFTQ = 3;
   */
  skipFTQ: boolean;
}
/**
 * @generated from protobuf message sparxweb.SubmitQuizAnswersResponse
 */
export interface SubmitQuizAnswersResponse {
  /**
   * Data about packages, tasks and task items that have changed.
   *
   * @generated from protobuf field: sparxweb.PackageData packageData = 1;
   */
  packageData?: PackageData;
  /**
   * List of awards given as a result of completing the quiz
   * (usually when it has made the task complete)
   * deprecated: use rewardGroups instead
   *
   * @deprecated
   * @generated from protobuf field: repeated sparxweb.Reward rewards = 2 [deprecated = true];
   */
  rewards: Reward[];
  /**
   * ordered list of grouped xp rewards for the quiz
   *
   * @generated from protobuf field: repeated sparxweb.RewardGroup rewardGroups = 3;
   */
  rewardGroups: RewardGroup[];
}
/**
 * @generated from protobuf message sparxweb.GetQuizResultsRequest
 */
export interface GetQuizResultsRequest {}
/**
 * @generated from protobuf message sparxweb.GetQuizResultsResponse
 */
export interface GetQuizResultsResponse {
  /**
   * @generated from protobuf field: repeated sparxweb.QuizResult results = 1;
   */
  results: QuizResult[];
}
/**
 * @generated from protobuf message sparxweb.QuizResult
 */
export interface QuizResult {
  /**
   * @generated from protobuf field: sparxweb.PackagePosition task = 1;
   */
  task?: PackagePosition;
  /**
   * @generated from protobuf field: int64 score = 2;
   */
  score: number;
  /**
   * @generated from protobuf field: int64 total = 3;
   */
  total: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp finishTime = 4;
   */
  finishTime?: Timestamp;
}
/**
 * @generated from protobuf message sparxweb.SetRevisionStreamRequest
 */
export interface SetRevisionStreamRequest {
  /**
   * Stream, e.g "GCSE/1", "KS3/2", etc
   *
   * @generated from protobuf field: string stream = 1;
   */
  stream: string;
}
/**
 * @generated from protobuf message sparxweb.SetRevisionStreamResponse
 */
export interface SetRevisionStreamResponse {}
/**
 * @generated from protobuf message sparxweb.GetActivityFeedRequest
 */
export interface GetActivityFeedRequest {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 1;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 2;
   */
  endDate?: Timestamp;
}
/**
 * @generated from protobuf message sparxweb.GetActivityFeedResponse
 */
export interface GetActivityFeedResponse {
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.ActivityFeedDay days = 1;
   */
  days: ActivityFeedDay[];
  /**
   * @generated from protobuf field: map<string, sparxweb.GetActivityFeedResponse.LearningUnit> learningUnits = 2;
   */
  learningUnits: {
    [key: string]: GetActivityFeedResponse_LearningUnit;
  };
}
/**
 * @generated from protobuf message sparxweb.GetActivityFeedResponse.LearningUnit
 */
export interface GetActivityFeedResponse_LearningUnit {
  /**
   * @generated from protobuf field: string title = 2;
   */
  title: string;
}
/**
 * @generated from protobuf message sparxweb.TetheringRequest
 */
export interface TetheringRequest {
  /**
   * Name of the tethering session to connect to.
   *
   * @generated from protobuf field: string sessionName = 1;
   */
  sessionName: string;
  /**
   * The last tetherID that was tethered on this session. This will be
   * compared to the current tetherID in the store. If the ID is the same
   * and `requestChangeNotification` is set to true then the handler will
   * return a WAIT result and will then watch the tether state and
   * asyncronously notify when the question is updated.
   *
   * @generated from protobuf field: string lastTetherID = 2;
   */
  lastTetherID: string;
  /**
   * Whether to start a loop on the response to notify the client when the
   * tether state has changed.
   *
   * @generated from protobuf field: bool requestChangeNotification = 3;
   */
  requestChangeNotification: boolean;
}
/**
 * @generated from protobuf message sparxweb.TetheringResponse
 */
export interface TetheringResponse {
  /**
   * @generated from protobuf field: sparxweb.TetheringResponse.TetherResult result = 1;
   */
  result: TetheringResponse_TetherResult;
  /**
   * Current tetherID in the tethering session.
   *
   * @generated from protobuf field: string tetherID = 2;
   */
  tetherID: string;
}
/**
 * @generated from protobuf enum sparxweb.TetheringResponse.TetherResult
 */
export enum TetheringResponse_TetherResult {
  /**
   * @generated from protobuf enum value: TETHER_RESULT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Tethering handler found a new question in the tether store so the
   * client should expect a new question activity.
   *
   * @generated from protobuf enum value: TETHER_RESULT_QUESTION = 1;
   */
  QUESTION = 1,
  /**
   * Tethering handler found that the last question the client requested
   * has not changed, so the client should wait for a notification for
   * a change to the session tether store.
   *
   * @generated from protobuf enum value: TETHER_RESULT_WAIT = 2;
   */
  WAIT = 2,
  /**
   * Tethering handler has found a new question in the tether store so
   * the client should make another tethering request to get the new
   * activity. This response will in as a notify.
   *
   * @generated from protobuf enum value: TETHER_RESULT_PENDING = 3;
   */
  PENDING = 3,
}
/**
 * @generated from protobuf message sparxweb.TetherQuestion
 */
export interface TetherQuestion {
  /**
   * Spec of the question (this is currently some JSON)
   *
   * @generated from protobuf field: string questionSpec = 1;
   */
  questionSpec: string;
  /**
   * The SkillID of the tethered question.
   *
   * @generated from protobuf field: string skillID = 2;
   */
  skillID: string;
  /**
   * QuestionID of the tethered question.
   *
   * @generated from protobuf field: string questionID = 3;
   */
  questionID: string;
  /**
   * Title of the question from the tether source.
   *
   * @generated from protobuf field: string title = 4;
   */
  title: string;
  /**
   * The activity index for this tethered activity
   *
   * @generated from protobuf field: int32 activityIndex = 5;
   */
  activityIndex: number;
  /**
   * Question index for this tethered activity
   *
   * @generated from protobuf field: int32 questionIndex = 6;
   */
  questionIndex: number;
  /**
   * Unique id for this tethered question. The value is changed
   * everytime a question is tethered even if the content hasn't
   * changed. This can be used by the client to load a new question
   * when it changes.
   *
   * The value of this string currently has no meaning.
   *
   * @generated from protobuf field: string tetherID = 7;
   */
  tetherID: string;
  /**
   * Whether this question is eligible for Multi-part marking
   *
   * @generated from protobuf field: bool multiPartMarking = 8;
   */
  multiPartMarking: boolean;
}
/**
 * @generated from protobuf message sparxweb.TetherVideo
 */
export interface TetherVideo {
  /**
   * ID of the video to tether
   *
   * @generated from protobuf field: string videoID = 1;
   */
  videoID: string;
  /**
   * URL to find the tethered video at
   *
   * @generated from protobuf field: string videoURL = 2;
   */
  videoURL: string;
}
/**
 * @generated from protobuf message sparxweb.PackageDeletionUpdate
 */
export interface PackageDeletionUpdate {
  /**
   * ID of the package
   *
   * @generated from protobuf field: string packageID = 1;
   */
  packageID: string;
  /**
   * When the package was marked as deleted
   *
   * @generated from protobuf field: google.protobuf.Timestamp deletionTime = 2;
   */
  deletionTime?: Timestamp;
}
/**
 * TODO: This endpoint has moved to sparx.games.v1.RecallStore.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.GetRecallFamilyStateRequest
 */
export interface GetRecallFamilyStateRequest {
  /**
   * @generated from protobuf field: string familyID = 1;
   */
  familyID: string;
}
/**
 * TODO: This endpoint has moved to sparx.games.v1.RecallStore.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.GetRecallFamilyStateResponse
 */
export interface GetRecallFamilyStateResponse {
  /**
   * @generated from protobuf field: recall.FamilyState familyState = 1;
   */
  familyState?: FamilyState;
}
/**
 * TODO: This endpoint has moved to sparx.games.v1.RecallStore.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.UpdateRecallUserDataRequest
 */
export interface UpdateRecallUserDataRequest {
  /**
   * @generated from protobuf field: string familyID = 1;
   */
  familyID: string;
  /**
   * @generated from protobuf field: recall.TargetSet targetSet = 2;
   */
  targetSet?: TargetSet;
  /**
   * Level the user finished the assessment on
   *
   * @generated from protobuf field: recall.Level level = 3;
   */
  level?: Level;
  // Information included for analytics only:

  /**
   * Details of the the previous assessment which give the level the user was on before starting this assessment
   *
   * @generated from protobuf field: recall.Level previousAssessment = 4;
   */
  previousAssessment?: Level;
  /**
   * If true, the user had a bad attempt at the assessment - i.e. they mashed the keyboard or
   * had a series of consecutive timeouts
   *
   * @generated from protobuf field: bool badAttempt = 5;
   */
  badAttempt: boolean;
  /**
   * Package ID of the homework the student was doing (if homework)
   *
   * @generated from protobuf field: string packageID = 6;
   */
  packageID: string;
  /**
   * Lesson Delivery ID of the lesson the student was doing (if a lesson)
   *
   * @generated from protobuf field: string lessonDeliveryID = 7;
   */
  lessonDeliveryID: string;
  /**
   * The name of the 100-club level the user reached
   *
   * @generated from protobuf field: string clubName = 8;
   */
  clubName: string;
  /**
   * The name of the 100-club level the user started the assessment on:
   *
   * @generated from protobuf field: string previousClubName = 9;
   */
  previousClubName: string;
  /**
   * A record of how many daily tokens the student has earned in each homework,
   * indexed by homeworkID
   *
   * @generated from protobuf field: map<string, int32> dailyTokensPerHomework = 10;
   */
  dailyTokensPerHomework: {
    [key: string]: number;
  };
}
/**
 * TODO: This endpoint has moved to sparx.games.v1.RecallStore.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.UpdateRecallUserDataResponse
 */
export interface UpdateRecallUserDataResponse {}
/**
 * TODO: This endpoint has moved to sparx.games.v1.RecallStore.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.GetRecallUserDataRequest
 */
export interface GetRecallUserDataRequest {
  /**
   * @generated from protobuf field: string familyID = 1;
   */
  familyID: string;
  /**
   * The time of the request, used to find daily results on the same day in
   * client time
   *
   * @generated from protobuf field: google.protobuf.Timestamp requestClientTime = 2;
   */
  requestClientTime?: Timestamp;
}
/**
 * TODO: This endpoint has moved to sparx.games.v1.RecallStore.
 * TODO: This message can be deleted once all calls are done through the new service.
 *
 * @generated from protobuf message sparxweb.GetRecallUserDataResponse
 */
export interface GetRecallUserDataResponse {
  /**
   * @generated from protobuf field: recall.TargetSet targetSet = 1;
   */
  targetSet?: TargetSet;
  /**
   * @generated from protobuf field: recall.Level level = 2;
   */
  level?: Level;
  /**
   * Results of all 100-club assessments in the last 36 hours, used to
   * determine whether to unlock non-100-Club games
   *
   * @generated from protobuf field: repeated recall.Level dailyResults = 3;
   */
  dailyResults: Level[];
  /**
   * A record of how many daily tokens the student has earned in each homework,
   * indexed by homeworkID
   *
   * @generated from protobuf field: map<string, int32> dailyTokensPerHomework = 4;
   */
  dailyTokensPerHomework: {
    [key: string]: number;
  };
}
/**
 * @generated from protobuf message sparxweb.GetTrainingPackagesRequest
 */
export interface GetTrainingPackagesRequest {}
/**
 * @generated from protobuf message sparxweb.AbortQuizRequest
 */
export interface AbortQuizRequest {
  /**
   * @generated from protobuf field: sparxweb.PackagePosition task = 1;
   */
  task?: PackagePosition;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparxweb.AbortQuizResponse
 */
export interface AbortQuizResponse {
  /**
   * Data about packages, tasks and task items that have changed.
   *
   * @generated from protobuf field: sparxweb.PackageData packageData = 1;
   */
  packageData?: PackageData;
}
/**
 * @generated from protobuf message sparxweb.GetStudentRewardsRequest
 */
export interface GetStudentRewardsRequest {}
/**
 * @generated from protobuf message sparxweb.XPLevel
 */
export interface XPLevel {
  /**
   * index of the level
   *
   * @generated from protobuf field: int32 level = 1;
   */
  level: number;
  /**
   * XP required to reach this level
   *
   * @generated from protobuf field: int32 xpRequired = 2;
   */
  xpRequired: number;
}
/**
 * @generated from protobuf message sparxweb.StudentRewards
 */
export interface StudentRewards {
  /**
   * Total XP points earned by the student.
   *
   * @generated from protobuf field: int32 xpPoints = 1;
   */
  xpPoints: number;
  /**
   * List of timestamps at which the student leveled up.
   *
   * @generated from protobuf field: repeated google.protobuf.Timestamp xpLevels = 2;
   */
  xpLevels: Timestamp[];
  /**
   * Current xp level of the student.
   *
   * @generated from protobuf field: sparxweb.XPLevel currentXPLevel = 3;
   */
  currentXPLevel?: XPLevel;
  /**
   * Next xp level for the student.
   *
   * @generated from protobuf field: sparxweb.XPLevel nextXPLevel = 4;
   */
  nextXPLevel?: XPLevel;
  /**
   * Whether the student is close to the next level.
   *
   * @generated from protobuf field: bool closeToNextLevel = 5;
   */
  closeToNextLevel: boolean;
  /**
   * The XP level of the student on the old XP system
   *
   * @generated from protobuf field: int32 oldLevel = 6;
   */
  oldLevel: number;
}
/**
 * @generated from protobuf message sparxweb.UpdateStudentLabelsRequest
 */
export interface UpdateStudentLabelsRequest {
  /**
   * @generated from protobuf field: map<string, string> labels = 1;
   */
  labels: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparxweb.UpdateStudentLabelsResponse
 */
export interface UpdateStudentLabelsResponse {
  /**
   * @generated from protobuf field: map<string, string> labels = 1;
   */
  labels: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf enum sparxweb.SparxWebFeatureSet
 */
export enum SparxWebFeatureSet {
  /**
   * Default flag for unknown.
   *
   * @generated from protobuf enum value: SparxWebFeatureSet_UNKNOWN = 0;
   */
  SparxWebFeatureSet_UNKNOWN = 0,
  /**
   * Features relating to completing homework
   *
   * @generated from protobuf enum value: SparxWebFeatureSet_HOMEWORK = 1;
   */
  SparxWebFeatureSet_HOMEWORK = 1,
  /**
   * Features relating to joining lessons
   *
   * @generated from protobuf enum value: SparxWebFeatureSet_LESSONS = 2;
   */
  SparxWebFeatureSet_LESSONS = 2,
  /**
   * Discovery: Features relating to english homework
   *
   * @generated from protobuf enum value: SparxWebFeatureSet_ENGLISH = 3;
   */
  SparxWebFeatureSet_ENGLISH = 3,
  /**
   * Discovery: Features relating to Quiz homework
   *
   * @generated from protobuf enum value: SparxWebFeatureSet_QUIZ = 4;
   */
  SparxWebFeatureSet_QUIZ = 4,
  /**
   * Discovery: Features relating to science homework
   *
   * @generated from protobuf enum value: SparxWebFeatureSet_SCIENCE = 5;
   */
  SparxWebFeatureSet_SCIENCE = 5,
  /**
   * Features relating to assessments
   *
   * @generated from protobuf enum value: SparxWebFeatureSet_ASSESSMENT = 6;
   */
  SparxWebFeatureSet_ASSESSMENT = 6,
}
/**
 * @generated from protobuf enum sparxweb.ActivityType
 */
export enum ActivityType {
  /**
   * A question
   *
   * @generated from protobuf enum value: QUESTION = 0;
   */
  QUESTION = 0,
  /**
   * A written answer check
   *
   * @generated from protobuf enum value: WAC = 1;
   */
  WAC = 1,
  /**
   * Deprecated in 2.71 - will be interpreted as REWARDGAME
   *
   * @generated from protobuf enum value: MINIGAME = 2;
   */
  MINIGAME = 2,
  /**
   * A quiz game (e.g. Picture Shuffle) entered freely
   *
   * @generated from protobuf enum value: QUIZGAME = 3;
   */
  QUIZGAME = 3,
  /**
   * A reward game (e.g. Find It) to give bonus XP
   *
   * @generated from protobuf enum value: REWARDGAME = 4;
   */
  REWARDGAME = 4,
  /**
   * This is a quiz type question, and is not related to QUIZGAME
   *
   * @generated from protobuf enum value: QUIZ = 5;
   */
  QUIZ = 5,
  /**
   * A video that must be watched
   *
   * @generated from protobuf enum value: VIDEO = 6;
   */
  VIDEO = 6,
}
/**
 * @generated from protobuf enum sparxweb.ActivityStatus
 */
export enum ActivityStatus {
  /**
   * @generated from protobuf enum value: UNINITIALISED = 0;
   */
  UNINITIALISED = 0,
  /**
   * @generated from protobuf enum value: READY = 1;
   */
  READY = 1,
  /**
   * @generated from protobuf enum value: ACTIVE = 2;
   */
  ACTIVE = 2,
  /**
   * @generated from protobuf enum value: SUCCESS = 3;
   */
  SUCCESS = 3,
  /**
   * @generated from protobuf enum value: FAILURE = 4;
   */
  FAILURE = 4,
  /**
   * @generated from protobuf enum value: CANCELED = 5;
   */
  CANCELED = 5,
  /**
   * @generated from protobuf enum value: ABORTED = 6;
   */
  ABORTED = 6,
  /**
   * @generated from protobuf enum value: ERROR = 7;
   */
  ERROR = 7,
}
/**
 * @generated from protobuf enum sparxweb.TaskItemStatus
 */
export enum TaskItemStatus {
  /**
   * Hasn't been attempted yet
   *
   * @generated from protobuf enum value: UNATTEMPTED = 0;
   */
  UNATTEMPTED = 0,
  /**
   * Done correctly last
   *
   * @generated from protobuf enum value: DONE = 1;
   */
  DONE = 1,
  /**
   * Done incorrectly last
   *
   * @generated from protobuf enum value: WRONG = 2;
   */
  WRONG = 2,
  /**
   * Done incorrectly too many times
   *
   * @generated from protobuf enum value: WARNING = 3;
   */
  WARNING = 3,
  /**
   * Done incorrectly enough times to require assistance
   *
   * @generated from protobuf enum value: SEEKHELP = 4;
   */
  SEEKHELP = 4,
  /**
   * May not have been attempted, but badged correct (used for FTQ)
   *
   * @generated from protobuf enum value: BADGED = 5;
   */
  BADGED = 5,
  /**
   * Task item should be hidden from student view (used for FTQ)
   *
   * @generated from protobuf enum value: HIDDEN = 6;
   */
  HIDDEN = 6,
  /**
   * Task item is incorrect, but we should nudge to watch the video
   *
   * @generated from protobuf enum value: WRONGWITHVIDEONUDGE = 7;
   */
  WRONGWITHVIDEONUDGE = 7,
  /**
   * Task item has been swapped out for an easier one
   *
   * @generated from protobuf enum value: SWAPPEDOUT = 8;
   */
  SWAPPEDOUT = 8,
}
/**
 * @generated from protobuf enum sparxweb.Hint
 */
export enum Hint {
  /**
   * @generated from protobuf enum value: HINT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: HINT_SIMPLIFY_FRACTION = 1;
   */
  SIMPLIFY_FRACTION = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class ErrorMessage$Type extends MessageType<ErrorMessage> {
  constructor() {
    super('sparxweb.ErrorMessage', [
      { no: 1, name: 'code', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.ErrorMessage
 */
export const ErrorMessage = new ErrorMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginRequest$Type extends MessageType<LoginRequest> {
  constructor() {
    super('sparxweb.LoginRequest', [
      { no: 1, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'password', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'app', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.LoginRequest
 */
export const LoginRequest = new LoginRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginResponse$Type extends MessageType<LoginResponse> {
  constructor() {
    super('sparxweb.LoginResponse', [
      { no: 1, name: 'session', kind: 'message', T: () => SparxSession },
      { no: 2, name: 'student', kind: 'message', T: () => SparxStudent },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.LoginResponse
 */
export const LoginResponse = new LoginResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionDetailsRequest$Type extends MessageType<SessionDetailsRequest> {
  constructor() {
    super('sparxweb.SessionDetailsRequest', [
      { no: 1, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 8,
        name: 'applicationName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'applicationVersion',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'browser', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'browserVersion',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'os', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'osVersion', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'clientFeatureFlags',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SessionDetailsRequest
 */
export const SessionDetailsRequest = new SessionDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionDetailsResponse$Type extends MessageType<SessionDetailsResponse> {
  constructor() {
    super('sparxweb.SessionDetailsResponse', [
      { no: 1, name: 'session', kind: 'message', T: () => SparxSession },
      { no: 2, name: 'student', kind: 'message', T: () => SparxStudent },
      { no: 9, name: 'feature_flags', kind: 'message', T: () => Struct },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SessionDetailsResponse
 */
export const SessionDetailsResponse = new SessionDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinLessonRequest$Type extends MessageType<JoinLessonRequest> {
  constructor() {
    super('sparxweb.JoinLessonRequest', [
      { no: 1, name: 'joinCode', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'joinLast', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'clientFeatureFlags',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 4,
        name: 'lessonDeliveryID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.JoinLessonRequest
 */
export const JoinLessonRequest = new JoinLessonRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinLessonResponse$Type extends MessageType<JoinLessonResponse> {
  constructor() {
    super('sparxweb.JoinLessonResponse', [
      {
        no: 1,
        name: 'lessonTitle',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'initialSessionUpdate',
        kind: 'message',
        T: () => SparxSessionUpdate,
      },
      {
        no: 3,
        name: 'lessonSettings',
        kind: 'message',
        T: () => LessonSettings,
      },
      {
        no: 4,
        name: 'lessonDeliveryID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'tutorialsCompleted',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'joinCode', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.JoinLessonResponse
 */
export const JoinLessonResponse = new JoinLessonResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveLessonRequest$Type extends MessageType<LeaveLessonRequest> {
  constructor() {
    super('sparxweb.LeaveLessonRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.LeaveLessonRequest
 */
export const LeaveLessonRequest = new LeaveLessonRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveLessonResponse$Type extends MessageType<LeaveLessonResponse> {
  constructor() {
    super('sparxweb.LeaveLessonResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.LeaveLessonResponse
 */
export const LeaveLessonResponse = new LeaveLessonResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SparxSessionUpdate$Type extends MessageType<SparxSessionUpdate> {
  constructor() {
    super('sparxweb.SparxSessionUpdate', [
      {
        no: 1,
        name: 'statusChange',
        kind: 'enum',
        T: () => [
          'sparxweb.SparxSessionUpdate.StatusChange',
          SparxSessionUpdate_StatusChange,
        ],
      },
      { no: 2, name: 'position', kind: 'message', T: () => PackagePosition },
      { no: 3, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'videoStatus',
        kind: 'enum',
        T: () => [
          'sparx.sparxweb.v1.VideoStatus',
          VideoStatus,
          'VIDEO_STATUS_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SparxSessionUpdate
 */
export const SparxSessionUpdate = new SparxSessionUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SparxSession$Type extends MessageType<SparxSession> {
  constructor() {
    super('sparxweb.SparxSession', [
      { no: 1, name: 'sessionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'currentPackagePosition',
        kind: 'message',
        T: () => PackagePosition,
      },
      { no: 3, name: 'currentActivity', kind: 'message', T: () => Activity },
      { no: 4, name: 'appID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'loginToken', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'tutorialsCompleted',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'rewardAlertsDismissed',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SparxSession
 */
export const SparxSession = new SparxSession$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SparxStudent$Type extends MessageType<SparxStudent> {
  constructor() {
    super('sparxweb.SparxStudent', [
      { no: 1, name: 'firstName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'lastName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 13, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'xPoints', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'xLevels',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Timestamp,
      },
      {
        no: 6,
        name: 'xThresholds',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'studentType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'lessonsEnabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 9,
        name: 'revisionStream',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'availableSessionTypes',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparxweb.SparxWebFeatureSet', SparxWebFeatureSet],
      },
      {
        no: 11,
        name: 'isPrimaryStudent',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 12,
        name: 'disableTablesGamesTimer',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 14,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 15,
        name: 'disableBookworkChecks',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SparxStudent
 */
export const SparxStudent = new SparxStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackagePosition$Type extends MessageType<PackagePosition> {
  constructor() {
    super('sparxweb.PackagePosition', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'taskIndex', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'taskItemIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'taskState',
        kind: 'enum',
        T: () => ['sparx.sparxweb.v1.TaskState', TaskState],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.PackagePosition
 */
export const PackagePosition = new PackagePosition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Activity$Type extends MessageType<Activity> {
  constructor() {
    super('sparxweb.Activity', [
      {
        no: 1,
        name: 'activityIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'activityType',
        kind: 'enum',
        T: () => ['sparxweb.ActivityType', ActivityType],
      },
      {
        no: 3,
        name: 'question',
        kind: 'message',
        oneof: 'payload',
        T: () => QuestionActivity,
      },
      { no: 4, name: 'game', kind: 'message', oneof: 'payload', T: () => Init },
      {
        no: 5,
        name: 'wac',
        kind: 'message',
        oneof: 'payload',
        T: () => WACActivity,
      },
      {
        no: 7,
        name: 'video',
        kind: 'message',
        oneof: 'payload',
        T: () => VideoActivity,
      },
      {
        no: 6,
        name: 'status',
        kind: 'enum',
        T: () => ['sparxweb.ActivityStatus', ActivityStatus],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.Activity
 */
export const Activity = new Activity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionActivity$Type extends MessageType<QuestionActivity> {
  constructor() {
    super('sparxweb.QuestionActivity', [
      {
        no: 1,
        name: 'questionIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 2, name: 'videoID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'questionSpec',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'bookworkCode',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'displayWacTutorial',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 6, name: 'skillID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'questionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'multiPartMarking',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.QuestionActivity
 */
export const QuestionActivity = new QuestionActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WACOption$Type extends MessageType<WACOption> {
  constructor() {
    super('sparxweb.WACOption', [
      { no: 1, name: 'wacedAnswer', kind: 'message', T: () => Answer },
      {
        no: 2,
        name: 'filledAnswerTemplate',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.WACOption
 */
export const WACOption = new WACOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WACActivity$Type extends MessageType<WACActivity> {
  constructor() {
    super('sparxweb.WACActivity', [
      {
        no: 1,
        name: 'questionSpec',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'wacedAnswer', kind: 'message', T: () => Answer },
      {
        no: 3,
        name: 'choices',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'bookworkCode',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'taskItem', kind: 'message', T: () => TaskItem },
      {
        no: 6,
        name: 'options',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => WACOption,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.WACActivity
 */
export const WACActivity = new WACActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoActivity$Type extends MessageType<VideoActivity> {
  constructor() {
    super('sparxweb.VideoActivity', [
      {
        no: 1,
        name: 'questionIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.VideoActivity
 */
export const VideoActivity = new VideoActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Award$Type extends MessageType<Award> {
  constructor() {
    super('sparxweb.Award', [
      { no: 1, name: 'currencyID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'amount', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.Award
 */
export const Award = new Award$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinSparxSessionRequest$Type extends MessageType<JoinSparxSessionRequest> {
  constructor() {
    super('sparxweb.JoinSparxSessionRequest', [
      { no: 1, name: 'sessionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'app', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.JoinSparxSessionRequest
 */
export const JoinSparxSessionRequest = new JoinSparxSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoutRequest$Type extends MessageType<LogoutRequest> {
  constructor() {
    super('sparxweb.LogoutRequest', [
      {
        no: 1,
        name: 'SessionID',
        kind: 'scalar',
        jsonName: 'SessionID',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.LogoutRequest
 */
export const LogoutRequest = new LogoutRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoutResponse$Type extends MessageType<LogoutResponse> {
  constructor() {
    super('sparxweb.LogoutResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.LogoutResponse
 */
export const LogoutResponse = new LogoutResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageDataRequest$Type extends MessageType<PackageDataRequest> {
  constructor() {
    super('sparxweb.PackageDataRequest', [
      {
        no: 2,
        name: 'includeAllActivePackages',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 3, name: 'getPackages', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'getTasks', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 5, name: 'getTaskItems', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 6, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'taskIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 8,
        name: 'taskItemIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.PackageDataRequest
 */
export const PackageDataRequest = new PackageDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageData$Type extends MessageType<PackageData> {
  constructor() {
    super('sparxweb.PackageData', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
      {
        no: 2,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Task,
      },
      {
        no: 3,
        name: 'taskItems',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TaskItem,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.PackageData
 */
export const PackageData = new PackageData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FeedbackNotificationRequest$Type extends MessageType<FeedbackNotificationRequest> {
  constructor() {
    super('sparxweb.FeedbackNotificationRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.FeedbackNotificationRequest
 */
export const FeedbackNotificationRequest =
  new FeedbackNotificationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FeedbackNotificationResponse$Type extends MessageType<FeedbackNotificationResponse> {
  constructor() {
    super('sparxweb.FeedbackNotificationResponse', [
      {
        no: 1,
        name: 'notifications',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => FeedbackNotification,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.FeedbackNotificationResponse
 */
export const FeedbackNotificationResponse =
  new FeedbackNotificationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FeedbackNotification$Type extends MessageType<FeedbackNotification> {
  constructor() {
    super('sparxweb.FeedbackNotification', [
      {
        no: 1,
        name: 'notificationID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'content', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'startTime', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'endTime', kind: 'message', T: () => Timestamp },
      {
        no: 6,
        name: 'feedbackType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'recipientType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.FeedbackNotification
 */
export const FeedbackNotification = new FeedbackNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetParentEmailSubscriptionStatusRequest$Type extends MessageType<GetParentEmailSubscriptionStatusRequest> {
  constructor() {
    super('sparxweb.GetParentEmailSubscriptionStatusRequest', [
      { no: 1, name: 'parentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetParentEmailSubscriptionStatusRequest
 */
export const GetParentEmailSubscriptionStatusRequest =
  new GetParentEmailSubscriptionStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetParentEmailSubscriptionStatusResponse$Type extends MessageType<GetParentEmailSubscriptionStatusResponse> {
  constructor() {
    super('sparxweb.GetParentEmailSubscriptionStatusResponse', [
      { no: 1, name: 'subscribed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetParentEmailSubscriptionStatusResponse
 */
export const GetParentEmailSubscriptionStatusResponse =
  new GetParentEmailSubscriptionStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetParentEmailSubscriptionStatusRequest$Type extends MessageType<SetParentEmailSubscriptionStatusRequest> {
  constructor() {
    super('sparxweb.SetParentEmailSubscriptionStatusRequest', [
      { no: 1, name: 'parentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'subscribed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SetParentEmailSubscriptionStatusRequest
 */
export const SetParentEmailSubscriptionStatusRequest =
  new SetParentEmailSubscriptionStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetParentEmailSubscriptionStatusResponse$Type extends MessageType<SetParentEmailSubscriptionStatusResponse> {
  constructor() {
    super('sparxweb.SetParentEmailSubscriptionStatusResponse', [
      { no: 1, name: 'subscribed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SetParentEmailSubscriptionStatusResponse
 */
export const SetParentEmailSubscriptionStatusResponse =
  new SetParentEmailSubscriptionStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentLastLoginAtRequest$Type extends MessageType<GetStudentLastLoginAtRequest> {
  constructor() {
    super('sparxweb.GetStudentLastLoginAtRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetStudentLastLoginAtRequest
 */
export const GetStudentLastLoginAtRequest =
  new GetStudentLastLoginAtRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentLastLoginAtResponse$Type extends MessageType<GetStudentLastLoginAtResponse> {
  constructor() {
    super('sparxweb.GetStudentLastLoginAtResponse', [
      { no: 1, name: 'lastLoginAt', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetStudentLastLoginAtResponse
 */
export const GetStudentLastLoginAtResponse =
  new GetStudentLastLoginAtResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Package$Type extends MessageType<Package> {
  constructor() {
    super('sparxweb.Package', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'endDate', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'packageType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'numTasks', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 7,
        name: 'numTaskItems',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'numTaskItemsDone',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 9,
        name: 'hasBeenComplete',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 10,
        name: 'numTasksComplete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'numTasksInProgress',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 12, name: 'status', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 13, name: 'xPoints', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 14, name: 'completion', kind: 'message', T: () => Completion },
      {
        no: 15,
        name: 'taskIds',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 16,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      { no: 17, name: 'deletedTime', kind: 'message', T: () => Timestamp },
      {
        no: 18,
        name: 'homeworkID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 19,
        name: 'numSwapsMade',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.Package
 */
export const Package = new Package$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Task$Type extends MessageType<Task> {
  constructor() {
    super('sparxweb.Task', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'taskIndex', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'taskType', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'numTaskItems',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'numTaskItemsDone',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'hasBeenComplete',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 9,
        name: 'numTaskItemsWrong',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 10, name: 'doNotWAC', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 11, name: 'completion', kind: 'message', T: () => Completion },
      {
        no: 12,
        name: 'completionStore',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 14,
        name: 'fixedQuestionDelivery',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 15,
        name: 'secondChanceDelivery',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.Task
 */
export const Task = new Task$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskItem$Type extends MessageType<TaskItem> {
  constructor() {
    super('sparxweb.TaskItem', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'taskIndex', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'taskItemIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'status',
        kind: 'enum',
        T: () => ['sparxweb.TaskItemStatus', TaskItemStatus],
      },
      {
        no: 5,
        name: 'numCorrectAnswers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'numWrongAnswers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'numVideoWatched',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 8, name: 'lastAnsweredAt', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 15, name: 'useStatus', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 16, name: 'rating', kind: 'message', T: () => Rating },
      {
        no: 17,
        name: 'numConsecutiveWrongAnswers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 18,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 19,
        name: 'numSkillQuestions',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 20,
        name: 'swappedInForIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 21,
        name: 'videoPlayed',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 22,
        name: 'videoOpenDuration',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.TaskItem
 */
export const TaskItem = new TaskItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivityRequest$Type extends MessageType<GetActivityRequest> {
  constructor() {
    super('sparxweb.GetActivityRequest', [
      {
        no: 1,
        name: 'activityType',
        kind: 'enum',
        T: () => ['sparxweb.ActivityType', ActivityType],
      },
      { no: 2, name: 'taskItem', kind: 'message', T: () => PackagePosition },
      { no: 4, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'gameID',
        kind: 'scalar',
        oneof: 'payload',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'method',
        kind: 'enum',
        T: () => [
          'sparxweb.GetActivityRequest.Method',
          GetActivityRequest_Method,
        ],
      },
      {
        no: 6,
        name: 'clientFeatureFlags',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetActivityRequest
 */
export const GetActivityRequest = new GetActivityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Ack$Type extends MessageType<Ack> {
  constructor() {
    super('sparxweb.Ack', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.Ack
 */
export const Ack = new Ack$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityAction$Type extends MessageType<ActivityAction> {
  constructor() {
    super('sparxweb.ActivityAction', [
      {
        no: 1,
        name: 'activityIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 2, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'video',
        kind: 'message',
        oneof: 'action',
        T: () => VideoAction,
      },
      {
        no: 4,
        name: 'question',
        kind: 'message',
        oneof: 'action',
        T: () => QuestionAction,
      },
      {
        no: 5,
        name: 'game',
        kind: 'message',
        oneof: 'action',
        T: () => Action,
      },
      {
        no: 6,
        name: 'wac',
        kind: 'message',
        oneof: 'action',
        T: () => WACAction,
      },
      {
        no: 7,
        name: 'quiz',
        kind: 'message',
        oneof: 'action',
        T: () => QuizAction,
      },
      {
        no: 8,
        name: 'video_question',
        kind: 'message',
        oneof: 'action',
        T: () => VideoQuestionAction,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.ActivityAction
 */
export const ActivityAction = new ActivityAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoAction$Type extends MessageType<VideoAction> {
  constructor() {
    super('sparxweb.VideoAction', [
      { no: 1, name: 'videoID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'actionType',
        kind: 'enum',
        T: () => ['sparxweb.VideoAction.ActionType', VideoAction_ActionType],
      },
      { no: 3, name: 'watchID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.VideoAction
 */
export const VideoAction = new VideoAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionAction$Type extends MessageType<QuestionAction> {
  constructor() {
    super('sparxweb.QuestionAction', [
      {
        no: 1,
        name: 'questionIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparxweb.QuestionAction.ActionType',
          QuestionAction_ActionType,
        ],
      },
      { no: 4, name: 'answer', kind: 'message', T: () => Answer },
      { no: 5, name: 'rating', kind: 'message', T: () => Rating },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.QuestionAction
 */
export const QuestionAction = new QuestionAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuizAction$Type extends MessageType<QuizAction> {
  constructor() {
    super('sparxweb.QuizAction', [
      {
        no: 1,
        name: 'questionIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'actionType',
        kind: 'enum',
        T: () => ['sparxweb.QuizAction.ActionType', QuizAction_ActionType],
      },
      { no: 3, name: 'answer', kind: 'message', T: () => Answer },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.QuizAction
 */
export const QuizAction = new QuizAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WACAction$Type extends MessageType<WACAction> {
  constructor() {
    super('sparxweb.WACAction', [
      {
        no: 3,
        name: 'actionType',
        kind: 'enum',
        T: () => ['sparxweb.WACAction.ActionType', WACAction_ActionType],
      },
      { no: 4, name: 'answer', kind: 'message', T: () => Answer },
      {
        no: 5,
        name: 'extraData',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.WACAction
 */
export const WACAction = new WACAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoQuestionAction$Type extends MessageType<VideoQuestionAction> {
  constructor() {
    super('sparxweb.VideoQuestionAction', [
      {
        no: 1,
        name: 'questionIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparxweb.VideoQuestionAction.ActionType',
          VideoQuestionAction_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.VideoQuestionAction
 */
export const VideoQuestionAction = new VideoQuestionAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Answer$Type extends MessageType<Answer> {
  constructor() {
    super('sparxweb.Answer', [
      {
        no: 1,
        name: 'components',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AnswerComponent,
      },
      { no: 2, name: 'hash', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.Answer
 */
export const Answer = new Answer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Rating$Type extends MessageType<Rating> {
  constructor() {
    super('sparxweb.Rating', [
      { no: 1, name: 'confidence', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'confidenceAfter',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.Rating
 */
export const Rating = new Rating$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Reward$Type extends MessageType<Reward> {
  constructor() {
    super('sparxweb.Reward', [
      { no: 1, name: 'xPoints', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'reason', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.Reward
 */
export const Reward = new Reward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RewardGroup$Type extends MessageType<RewardGroup> {
  constructor() {
    super('sparxweb.RewardGroup', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['sparxweb.RewardGroup.Type', RewardGroup_Type],
      },
      {
        no: 2,
        name: 'rewards',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Reward,
      },
      {
        no: 3,
        name: 'xp_state_after_applying',
        kind: 'message',
        T: () => StudentRewards,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.RewardGroup
 */
export const RewardGroup = new RewardGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionResponse$Type extends MessageType<ActivityActionResponse> {
  constructor() {
    super('sparxweb.ActivityActionResponse', [
      { no: 1, name: 'success', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'status', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'consecutiveWAC',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'rewards',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Reward,
      },
      {
        no: 5,
        name: 'gameUpdate',
        kind: 'message',
        oneof: 'update',
        T: () => Update,
      },
      {
        no: 6,
        name: 'givenAnswerXML',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'taskItemStatus',
        kind: 'enum',
        T: () => ['sparxweb.TaskItemStatus', TaskItemStatus],
      },
      {
        no: 8,
        name: 'gap_evaluations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => GapEvaluation },
      },
      {
        no: 9,
        name: 'hints',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'enum', T: () => ['sparxweb.Hint', Hint, 'HINT_'] },
      },
      {
        no: 10,
        name: 'rewardGroups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RewardGroup,
      },
      {
        no: 11,
        name: 'numHintsGiven',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.ActivityActionResponse
 */
export const ActivityActionResponse = new ActivityActionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GapEvaluation$Type extends MessageType<GapEvaluation> {
  constructor() {
    super('sparxweb.GapEvaluation', [
      { no: 1, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GapEvaluation
 */
export const GapEvaluation = new GapEvaluation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetsTokenRequest$Type extends MessageType<AssetsTokenRequest> {
  constructor() {
    super('sparxweb.AssetsTokenRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.AssetsTokenRequest
 */
export const AssetsTokenRequest = new AssetsTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetsTokenResponse$Type extends MessageType<AssetsTokenResponse> {
  constructor() {
    super('sparxweb.AssetsTokenResponse', [
      { no: 1, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'assetServerURL',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'validFrom', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'expiresAt', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.AssetsTokenResponse
 */
export const AssetsTokenResponse = new AssetsTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ThrottledErrorDetails$Type extends MessageType<ThrottledErrorDetails> {
  constructor() {
    super('sparxweb.ThrottledErrorDetails', [
      { no: 1, name: 'delay', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.ThrottledErrorDetails
 */
export const ThrottledErrorDetails = new ThrottledErrorDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserActive$Type extends MessageType<UserActive> {
  constructor() {
    super('sparxweb.UserActive', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UserActive
 */
export const UserActive = new UserActive$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebugInfoRequest$Type extends MessageType<DebugInfoRequest> {
  constructor() {
    super('sparxweb.DebugInfoRequest', [
      {
        no: 1,
        name: 'activityIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.DebugInfoRequest
 */
export const DebugInfoRequest = new DebugInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebugInfoResponse$Type extends MessageType<DebugInfoResponse> {
  constructor() {
    super('sparxweb.DebugInfoResponse', [
      {
        no: 1,
        name: 'info',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => DebugInfoResponse_DebugInfo,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.DebugInfoResponse
 */
export const DebugInfoResponse = new DebugInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebugInfoResponse_DebugInfo$Type extends MessageType<DebugInfoResponse_DebugInfo> {
  constructor() {
    super('sparxweb.DebugInfoResponse.DebugInfo', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'value', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.DebugInfoResponse.DebugInfo
 */
export const DebugInfoResponse_DebugInfo =
  new DebugInfoResponse_DebugInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTutorialsRequest$Type extends MessageType<UpdateTutorialsRequest> {
  constructor() {
    super('sparxweb.UpdateTutorialsRequest', [
      {
        no: 1,
        name: 'tutorialsCompleted',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UpdateTutorialsRequest
 */
export const UpdateTutorialsRequest = new UpdateTutorialsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRewardAlertsRequest$Type extends MessageType<UpdateRewardAlertsRequest> {
  constructor() {
    super('sparxweb.UpdateRewardAlertsRequest', [
      {
        no: 1,
        name: 'rewardAlertsDismissed',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UpdateRewardAlertsRequest
 */
export const UpdateRewardAlertsRequest = new UpdateRewardAlertsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGameProgressRequest$Type extends MessageType<GetGameProgressRequest> {
  constructor() {
    super('sparxweb.GetGameProgressRequest', [
      { no: 1, name: 'dataId', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'questionSet',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetGameProgressRequest
 */
export const GetGameProgressRequest = new GetGameProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGameProgressResponse$Type extends MessageType<GetGameProgressResponse> {
  constructor() {
    super('sparxweb.GetGameProgressResponse', [
      { no: 1, name: 'storedData', kind: 'message', T: () => Store },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetGameProgressResponse
 */
export const GetGameProgressResponse = new GetGameProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateGameProgressRequest$Type extends MessageType<UpdateGameProgressRequest> {
  constructor() {
    super('sparxweb.UpdateGameProgressRequest', [
      { no: 1, name: 'dataId', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'questionSet',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'updatedData', kind: 'message', T: () => Store },
      { no: 3, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UpdateGameProgressRequest
 */
export const UpdateGameProgressRequest = new UpdateGameProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateGameProgressResponse$Type extends MessageType<UpdateGameProgressResponse> {
  constructor() {
    super('sparxweb.UpdateGameProgressResponse', [
      {
        no: 1,
        name: 'updateStates',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => GameProgressUpdateState },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UpdateGameProgressResponse
 */
export const UpdateGameProgressResponse = new UpdateGameProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameProgressUpdateState$Type extends MessageType<GameProgressUpdateState> {
  constructor() {
    super('sparxweb.GameProgressUpdateState', [
      { no: 1, name: 'success', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'reason', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GameProgressUpdateState
 */
export const GameProgressUpdateState = new GameProgressUpdateState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SkipGameWithXPRequest$Type extends MessageType<SkipGameWithXPRequest> {
  constructor() {
    super('sparxweb.SkipGameWithXPRequest', [
      { no: 1, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SkipGameWithXPRequest
 */
export const SkipGameWithXPRequest = new SkipGameWithXPRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentInsightsRequest$Type extends MessageType<StudentInsightsRequest> {
  constructor() {
    super('sparxweb.StudentInsightsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.StudentInsightsRequest
 */
export const StudentInsightsRequest = new StudentInsightsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageHistoryResponse$Type extends MessageType<PackageHistoryResponse> {
  constructor() {
    super('sparxweb.PackageHistoryResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
      {
        no: 2,
        name: 'taskItems',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.PackageHistoryResponse
 */
export const PackageHistoryResponse = new PackageHistoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PracticeHomeworksResponse$Type extends MessageType<PracticeHomeworksResponse> {
  constructor() {
    super('sparxweb.PracticeHomeworksResponse', [
      { no: 1, name: 'nextOptionalTask', kind: 'message', T: () => Task },
      { no: 2, name: 'nextTargetTask', kind: 'message', T: () => Task },
      {
        no: 3,
        name: 'nextTargetVideoID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.PracticeHomeworksResponse
 */
export const PracticeHomeworksResponse = new PracticeHomeworksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HowMuchLearntResponse$Type extends MessageType<HowMuchLearntResponse> {
  constructor() {
    super('sparxweb.HowMuchLearntResponse', [
      {
        no: 1,
        name: 'mostLearntSubstrand',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'leastLearntSubstrand',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'strands',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: {
          kind: 'enum',
          T: () => [
            'sparxweb.HowMuchLearntResponse.StrandStatus',
            HowMuchLearntResponse_StrandStatus,
          ],
        },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.HowMuchLearntResponse
 */
export const HowMuchLearntResponse = new HowMuchLearntResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentInsightsResponse$Type extends MessageType<StudentInsightsResponse> {
  constructor() {
    super('sparxweb.StudentInsightsResponse', [
      {
        no: 1,
        name: 'packageHistory',
        kind: 'message',
        T: () => PackageHistoryResponse,
      },
      {
        no: 2,
        name: 'practice',
        kind: 'message',
        T: () => PracticeHomeworksResponse,
      },
      {
        no: 3,
        name: 'howMuchLearnt',
        kind: 'message',
        T: () => HowMuchLearntResponse,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.StudentInsightsResponse
 */
export const StudentInsightsResponse = new StudentInsightsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLeagueResultsRequest$Type extends MessageType<GetLeagueResultsRequest> {
  constructor() {
    super('sparxweb.GetLeagueResultsRequest', [
      { no: 1, name: 'gameID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'startTimestamp', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'endTimestamp', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'medalStartWeekIndex',
        kind: 'message',
        T: () => WeekIndex,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetLeagueResultsRequest
 */
export const GetLeagueResultsRequest = new GetLeagueResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLeagueResultsResponse$Type extends MessageType<GetLeagueResultsResponse> {
  constructor() {
    super('sparxweb.GetLeagueResultsResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Result,
      },
      {
        no: 2,
        name: 'medals',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Medal,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetLeagueResultsResponse
 */
export const GetLeagueResultsResponse = new GetLeagueResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveLeagueGameScoreRequest$Type extends MessageType<SaveLeagueGameScoreRequest> {
  constructor() {
    super('sparxweb.SaveLeagueGameScoreRequest', [
      { no: 1, name: 'result', kind: 'message', T: () => Result },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SaveLeagueGameScoreRequest
 */
export const SaveLeagueGameScoreRequest = new SaveLeagueGameScoreRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveLeagueGameScoreResponse$Type extends MessageType<SaveLeagueGameScoreResponse> {
  constructor() {
    super('sparxweb.SaveLeagueGameScoreResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SaveLeagueGameScoreResponse
 */
export const SaveLeagueGameScoreResponse =
  new SaveLeagueGameScoreResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLeagueAvatarRequest$Type extends MessageType<GetLeagueAvatarRequest> {
  constructor() {
    super('sparxweb.GetLeagueAvatarRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetLeagueAvatarRequest
 */
export const GetLeagueAvatarRequest = new GetLeagueAvatarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLeagueAvatarResponse$Type extends MessageType<GetLeagueAvatarResponse> {
  constructor() {
    super('sparxweb.GetLeagueAvatarResponse', [
      { no: 1, name: 'avatar', kind: 'message', T: () => Avatar },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetLeagueAvatarResponse
 */
export const GetLeagueAvatarResponse = new GetLeagueAvatarResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetLeagueAvatarRequest$Type extends MessageType<SetLeagueAvatarRequest> {
  constructor() {
    super('sparxweb.SetLeagueAvatarRequest', [
      { no: 1, name: 'avatar', kind: 'message', T: () => Avatar },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SetLeagueAvatarRequest
 */
export const SetLeagueAvatarRequest = new SetLeagueAvatarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLeagueMedalsRequest$Type extends MessageType<GetLeagueMedalsRequest> {
  constructor() {
    super('sparxweb.GetLeagueMedalsRequest', [
      { no: 1, name: 'gameID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'medalStartWeekIndex',
        kind: 'message',
        T: () => WeekIndex,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetLeagueMedalsRequest
 */
export const GetLeagueMedalsRequest = new GetLeagueMedalsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLeagueMedalsResponse$Type extends MessageType<GetLeagueMedalsResponse> {
  constructor() {
    super('sparxweb.GetLeagueMedalsResponse', [
      {
        no: 1,
        name: 'medals',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Medal,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetLeagueMedalsResponse
 */
export const GetLeagueMedalsResponse = new GetLeagueMedalsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetLeagueAvatarResponse$Type extends MessageType<SetLeagueAvatarResponse> {
  constructor() {
    super('sparxweb.SetLeagueAvatarResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SetLeagueAvatarResponse
 */
export const SetLeagueAvatarResponse = new SetLeagueAvatarResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitQuizAnswersRequest$Type extends MessageType<SubmitQuizAnswersRequest> {
  constructor() {
    super('sparxweb.SubmitQuizAnswersRequest', [
      { no: 1, name: 'task', kind: 'message', T: () => PackagePosition },
      { no: 2, name: 'timestamp', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'skipFTQ', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SubmitQuizAnswersRequest
 */
export const SubmitQuizAnswersRequest = new SubmitQuizAnswersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitQuizAnswersResponse$Type extends MessageType<SubmitQuizAnswersResponse> {
  constructor() {
    super('sparxweb.SubmitQuizAnswersResponse', [
      { no: 1, name: 'packageData', kind: 'message', T: () => PackageData },
      {
        no: 2,
        name: 'rewards',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Reward,
      },
      {
        no: 3,
        name: 'rewardGroups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RewardGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SubmitQuizAnswersResponse
 */
export const SubmitQuizAnswersResponse = new SubmitQuizAnswersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuizResultsRequest$Type extends MessageType<GetQuizResultsRequest> {
  constructor() {
    super('sparxweb.GetQuizResultsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetQuizResultsRequest
 */
export const GetQuizResultsRequest = new GetQuizResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuizResultsResponse$Type extends MessageType<GetQuizResultsResponse> {
  constructor() {
    super('sparxweb.GetQuizResultsResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => QuizResult,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetQuizResultsResponse
 */
export const GetQuizResultsResponse = new GetQuizResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuizResult$Type extends MessageType<QuizResult> {
  constructor() {
    super('sparxweb.QuizResult', [
      { no: 1, name: 'task', kind: 'message', T: () => PackagePosition },
      {
        no: 2,
        name: 'score',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 3,
        name: 'total',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 4, name: 'finishTime', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.QuizResult
 */
export const QuizResult = new QuizResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetRevisionStreamRequest$Type extends MessageType<SetRevisionStreamRequest> {
  constructor() {
    super('sparxweb.SetRevisionStreamRequest', [
      { no: 1, name: 'stream', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SetRevisionStreamRequest
 */
export const SetRevisionStreamRequest = new SetRevisionStreamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetRevisionStreamResponse$Type extends MessageType<SetRevisionStreamResponse> {
  constructor() {
    super('sparxweb.SetRevisionStreamResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.SetRevisionStreamResponse
 */
export const SetRevisionStreamResponse = new SetRevisionStreamResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivityFeedRequest$Type extends MessageType<GetActivityFeedRequest> {
  constructor() {
    super('sparxweb.GetActivityFeedRequest', [
      { no: 1, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'endDate', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetActivityFeedRequest
 */
export const GetActivityFeedRequest = new GetActivityFeedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivityFeedResponse$Type extends MessageType<GetActivityFeedResponse> {
  constructor() {
    super('sparxweb.GetActivityFeedResponse', [
      {
        no: 1,
        name: 'days',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ActivityFeedDay,
      },
      {
        no: 2,
        name: 'learningUnits',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => GetActivityFeedResponse_LearningUnit },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetActivityFeedResponse
 */
export const GetActivityFeedResponse = new GetActivityFeedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivityFeedResponse_LearningUnit$Type extends MessageType<GetActivityFeedResponse_LearningUnit> {
  constructor() {
    super('sparxweb.GetActivityFeedResponse.LearningUnit', [
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetActivityFeedResponse.LearningUnit
 */
export const GetActivityFeedResponse_LearningUnit =
  new GetActivityFeedResponse_LearningUnit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TetheringRequest$Type extends MessageType<TetheringRequest> {
  constructor() {
    super('sparxweb.TetheringRequest', [
      {
        no: 1,
        name: 'sessionName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'lastTetherID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'requestChangeNotification',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.TetheringRequest
 */
export const TetheringRequest = new TetheringRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TetheringResponse$Type extends MessageType<TetheringResponse> {
  constructor() {
    super('sparxweb.TetheringResponse', [
      {
        no: 1,
        name: 'result',
        kind: 'enum',
        T: () => [
          'sparxweb.TetheringResponse.TetherResult',
          TetheringResponse_TetherResult,
          'TETHER_RESULT_',
        ],
      },
      { no: 2, name: 'tetherID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.TetheringResponse
 */
export const TetheringResponse = new TetheringResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TetherQuestion$Type extends MessageType<TetherQuestion> {
  constructor() {
    super('sparxweb.TetherQuestion', [
      {
        no: 1,
        name: 'questionSpec',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'skillID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'questionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'activityIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'questionIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 7, name: 'tetherID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'multiPartMarking',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.TetherQuestion
 */
export const TetherQuestion = new TetherQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TetherVideo$Type extends MessageType<TetherVideo> {
  constructor() {
    super('sparxweb.TetherVideo', [
      { no: 1, name: 'videoID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'videoURL', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.TetherVideo
 */
export const TetherVideo = new TetherVideo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageDeletionUpdate$Type extends MessageType<PackageDeletionUpdate> {
  constructor() {
    super('sparxweb.PackageDeletionUpdate', [
      { no: 1, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'deletionTime', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.PackageDeletionUpdate
 */
export const PackageDeletionUpdate = new PackageDeletionUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecallFamilyStateRequest$Type extends MessageType<GetRecallFamilyStateRequest> {
  constructor() {
    super('sparxweb.GetRecallFamilyStateRequest', [
      { no: 1, name: 'familyID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetRecallFamilyStateRequest
 */
export const GetRecallFamilyStateRequest =
  new GetRecallFamilyStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecallFamilyStateResponse$Type extends MessageType<GetRecallFamilyStateResponse> {
  constructor() {
    super('sparxweb.GetRecallFamilyStateResponse', [
      { no: 1, name: 'familyState', kind: 'message', T: () => FamilyState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetRecallFamilyStateResponse
 */
export const GetRecallFamilyStateResponse =
  new GetRecallFamilyStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRecallUserDataRequest$Type extends MessageType<UpdateRecallUserDataRequest> {
  constructor() {
    super('sparxweb.UpdateRecallUserDataRequest', [
      { no: 1, name: 'familyID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'targetSet', kind: 'message', T: () => TargetSet },
      { no: 3, name: 'level', kind: 'message', T: () => Level },
      { no: 4, name: 'previousAssessment', kind: 'message', T: () => Level },
      { no: 5, name: 'badAttempt', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 6, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'lessonDeliveryID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 8, name: 'clubName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 9,
        name: 'previousClubName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'dailyTokensPerHomework',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UpdateRecallUserDataRequest
 */
export const UpdateRecallUserDataRequest =
  new UpdateRecallUserDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRecallUserDataResponse$Type extends MessageType<UpdateRecallUserDataResponse> {
  constructor() {
    super('sparxweb.UpdateRecallUserDataResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UpdateRecallUserDataResponse
 */
export const UpdateRecallUserDataResponse =
  new UpdateRecallUserDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecallUserDataRequest$Type extends MessageType<GetRecallUserDataRequest> {
  constructor() {
    super('sparxweb.GetRecallUserDataRequest', [
      { no: 1, name: 'familyID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'requestClientTime', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetRecallUserDataRequest
 */
export const GetRecallUserDataRequest = new GetRecallUserDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecallUserDataResponse$Type extends MessageType<GetRecallUserDataResponse> {
  constructor() {
    super('sparxweb.GetRecallUserDataResponse', [
      { no: 1, name: 'targetSet', kind: 'message', T: () => TargetSet },
      { no: 2, name: 'level', kind: 'message', T: () => Level },
      {
        no: 3,
        name: 'dailyResults',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Level,
      },
      {
        no: 4,
        name: 'dailyTokensPerHomework',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetRecallUserDataResponse
 */
export const GetRecallUserDataResponse = new GetRecallUserDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrainingPackagesRequest$Type extends MessageType<GetTrainingPackagesRequest> {
  constructor() {
    super('sparxweb.GetTrainingPackagesRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetTrainingPackagesRequest
 */
export const GetTrainingPackagesRequest = new GetTrainingPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AbortQuizRequest$Type extends MessageType<AbortQuizRequest> {
  constructor() {
    super('sparxweb.AbortQuizRequest', [
      { no: 1, name: 'task', kind: 'message', T: () => PackagePosition },
      { no: 2, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.AbortQuizRequest
 */
export const AbortQuizRequest = new AbortQuizRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AbortQuizResponse$Type extends MessageType<AbortQuizResponse> {
  constructor() {
    super('sparxweb.AbortQuizResponse', [
      { no: 1, name: 'packageData', kind: 'message', T: () => PackageData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.AbortQuizResponse
 */
export const AbortQuizResponse = new AbortQuizResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentRewardsRequest$Type extends MessageType<GetStudentRewardsRequest> {
  constructor() {
    super('sparxweb.GetStudentRewardsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GetStudentRewardsRequest
 */
export const GetStudentRewardsRequest = new GetStudentRewardsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class XPLevel$Type extends MessageType<XPLevel> {
  constructor() {
    super('sparxweb.XPLevel', [
      { no: 1, name: 'level', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'xpRequired', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.XPLevel
 */
export const XPLevel = new XPLevel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentRewards$Type extends MessageType<StudentRewards> {
  constructor() {
    super('sparxweb.StudentRewards', [
      { no: 1, name: 'xpPoints', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'xpLevels',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Timestamp,
      },
      { no: 3, name: 'currentXPLevel', kind: 'message', T: () => XPLevel },
      { no: 4, name: 'nextXPLevel', kind: 'message', T: () => XPLevel },
      {
        no: 5,
        name: 'closeToNextLevel',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 6, name: 'oldLevel', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.StudentRewards
 */
export const StudentRewards = new StudentRewards$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentLabelsRequest$Type extends MessageType<UpdateStudentLabelsRequest> {
  constructor() {
    super('sparxweb.UpdateStudentLabelsRequest', [
      {
        no: 1,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UpdateStudentLabelsRequest
 */
export const UpdateStudentLabelsRequest = new UpdateStudentLabelsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentLabelsResponse$Type extends MessageType<UpdateStudentLabelsResponse> {
  constructor() {
    super('sparxweb.UpdateStudentLabelsResponse', [
      {
        no: 1,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.UpdateStudentLabelsResponse
 */
export const UpdateStudentLabelsResponse =
  new UpdateStudentLabelsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparxweb.SparxServer
 */
export const SparxServer = new ServiceType('sparxweb.SparxServer', [
  { name: 'Login', options: {}, I: LoginRequest, O: LoginResponse },
  {
    name: 'GetSessionDetails',
    options: {},
    I: SessionDetailsRequest,
    O: SessionDetailsResponse,
  },
  {
    name: 'JoinLesson',
    options: {},
    I: JoinLessonRequest,
    O: JoinLessonResponse,
  },
  {
    name: 'LeaveLesson',
    options: {},
    I: LeaveLessonRequest,
    O: LeaveLessonResponse,
  },
  {
    name: 'JoinSparxSession',
    options: {},
    I: JoinSparxSessionRequest,
    O: LoginResponse,
  },
  { name: 'Logout', options: {}, I: LogoutRequest, O: LogoutResponse },
  {
    name: 'GetPackageData',
    options: {},
    I: PackageDataRequest,
    O: PackageData,
  },
  { name: 'GetActivity', options: {}, I: GetActivityRequest, O: Activity },
  {
    name: 'SendActivityAction',
    options: {},
    I: ActivityAction,
    O: ActivityActionResponse,
  },
  {
    name: 'GetAssetsToken',
    options: {},
    I: AssetsTokenRequest,
    O: AssetsTokenResponse,
  },
  {
    name: 'GetDebugInfo',
    options: {},
    I: DebugInfoRequest,
    O: DebugInfoResponse,
  },
  { name: 'UpdateTutorials', options: {}, I: UpdateTutorialsRequest, O: Ack },
  {
    name: 'UpdateRewardAlerts',
    options: {},
    I: UpdateRewardAlertsRequest,
    O: Ack,
  },
  {
    name: 'GetGameProgress',
    options: {},
    I: GetGameProgressRequest,
    O: GetGameProgressResponse,
  },
  {
    name: 'GetLeagueResults',
    options: {},
    I: GetLeagueResultsRequest,
    O: GetLeagueResultsResponse,
  },
  {
    name: 'SaveLeagueGameScore',
    options: {},
    I: SaveLeagueGameScoreRequest,
    O: SaveLeagueGameScoreResponse,
  },
  {
    name: 'GetLeagueAvatar',
    options: {},
    I: GetLeagueAvatarRequest,
    O: GetLeagueAvatarResponse,
  },
  {
    name: 'SetLeagueAvatar',
    options: {},
    I: SetLeagueAvatarRequest,
    O: SetLeagueAvatarResponse,
  },
  {
    name: 'GetLeagueMedals',
    options: {},
    I: GetLeagueMedalsRequest,
    O: GetLeagueMedalsResponse,
  },
  {
    name: 'SubmitQuizAnswers',
    options: {},
    I: SubmitQuizAnswersRequest,
    O: SubmitQuizAnswersResponse,
  },
  { name: 'AbortQuiz', options: {}, I: AbortQuizRequest, O: AbortQuizResponse },
  {
    name: 'GetQuizResults',
    options: {},
    I: GetQuizResultsRequest,
    O: GetQuizResultsResponse,
  },
  {
    name: 'GetActivityFeed',
    options: {},
    I: GetActivityFeedRequest,
    O: GetActivityFeedResponse,
  },
  {
    name: 'SetRevisionStream',
    options: {},
    I: SetRevisionStreamRequest,
    O: SetRevisionStreamResponse,
  },
  { name: 'Tethering', options: {}, I: TetheringRequest, O: TetheringResponse },
  {
    name: 'GetRecallFamilyState',
    options: {},
    I: GetRecallFamilyStateRequest,
    O: GetRecallFamilyStateResponse,
  },
  {
    name: 'UpdateRecallUserData',
    options: {},
    I: UpdateRecallUserDataRequest,
    O: UpdateRecallUserDataResponse,
  },
  {
    name: 'GetRecallUserData',
    options: {},
    I: GetRecallUserDataRequest,
    O: GetRecallUserDataResponse,
  },
  {
    name: 'GetTrainingPackages',
    options: {},
    I: GetTrainingPackagesRequest,
    O: PackageData,
  },
  {
    name: 'GetStudentRewards',
    options: {},
    I: GetStudentRewardsRequest,
    O: StudentRewards,
  },
  {
    name: 'SubmitStudentSentimentFeedback',
    options: {},
    I: SubmitStudentSentimentFeedbackRequest,
    O: SubmitStudentSentimentFeedbackResponse,
  },
  {
    name: 'UpdateStudentLabels',
    options: {},
    I: UpdateStudentLabelsRequest,
    O: UpdateStudentLabelsResponse,
  },
]);
/**
 * @generated ServiceType for protobuf service sparxweb.SparxClient
 */
export const SparxClient = new ServiceType('sparxweb.SparxClient', [
  { name: 'UpdatePackageData', options: {}, I: PackageData, O: Ack },
  { name: 'UpdateStudent', options: {}, I: SparxStudent, O: Ack },
  { name: 'UpdateSession', options: {}, I: SparxSession, O: Ack },
]);
