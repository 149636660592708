import * as Sentry from '@sentry/react';
import { isSystemAccessError } from '@sparx/api/utils/rpc-error';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Button } from 'components/button/Button';
import { PropsWithChildren } from 'react';
import { isRouteErrorResponse, Outlet, useRouteError } from 'react-router-dom';

import { NotFound, UnknownError } from './ErrorPages';
import styles from './GlobalErrorBoundary.module.css';

/**
 * This is built to be used as the top level react router element/Component which wraps all other
 * routes.
 */
export const GlobalErrorBoundary = () => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <Sentry.ErrorBoundary
        onReset={reset}
        fallback={({ resetError, error }) => (
          <ErrorPage
            error={error}
            reset={resetError}
            systemSelectUrl={window.settings?.systemSelectUrl ?? 'https://app.sparx-learning.com'}
          />
        )}
      >
        <Outlet />
      </Sentry.ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

/**
 * This is built to be passed to the top level react router errorElement/ErrorBoundary which will
 * catch any uncaught errors from routes.
 */
export const GlobalRouteError = () => {
  const error = useRouteError() as Error;

  return (
    <ErrorPage
      error={error}
      systemSelectUrl={window.settings?.systemSelectUrl ?? 'https://app.sparx-learning.com'}
    />
  );
};

const ErrorPage = ({
  error,
  reset,
  systemSelectUrl,
}: {
  error: Error;
  systemSelectUrl: string;
  reset?: () => void;
}) => (
  <div className={styles.Container}>
    <Content error={error} reset={reset} systemSelectUrl={systemSelectUrl} />
  </div>
);

const Content = ({
  error,
  systemSelectUrl,
}: {
  error: Error;
  systemSelectUrl: string;
  reset?: () => void;
}) => {
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFound />;
    }
    return <UnknownError />;
  }

  if (isSystemAccessError(error)) {
    return (
      <Card>
        <p className={styles.Title}>You don&apos;t have access to this system</p>
        <p className={styles.Content}>
          If you require access please contact your school&apos;s IT team, or select another Sparx
          Learning system
        </p>
        <Button className={styles.Button} as="a" href={systemSelectUrl}>
          Go to system select
        </Button>
      </Card>
    );
  }

  return (
    <Card>
      <p className={styles.Title}>Oops - An error has occurred</p>
      <p className={styles.Content}>We&apos;re really sorry but an error has occurred.</p>
      <p className={styles.Content}>
        Please <strong>refresh your page</strong> and try again.
      </p>
    </Card>
  );
};

const Card = ({ children }: PropsWithChildren) => {
  return <div className={styles.Card}>{children}</div>;
};

export default ErrorPage;
